.stand-detail-delegates-bar-open-info-floating-container{
    position:absolute;
    left:0px;
    top:200px;
    width:80px;
    height:80px;
    background-color:#008457;
    z-index:400;
    cursor: pointer;
}


.stand-detail-delegates-bar-open-info-numbers-container{
    position:absolute;
    top:8px;
    right:10px;
    width:25px;
    height:25px;
    background-color:#ffffff;
    border-radius:50%;
    text-align:center;
    color:#008457;
    font-family: MyriadPro;
    font-size:14px;
    line-height:1.9;
    font-weight:bold;
}

.stand-detail-delegates-bar-open-info-icon-container{
    width:35px;
    height:37px;
    position:absolute;
    background-image:url(/img/stand-detail-delegates-bar-open-info-icon.png);
    background-position:center;
    background-repeat:no-repeat;
    background-size:contain;
    left:0px;
    right:0px;
    top:33px;
    margin-left:auto;
    margin-right:auto;
}



.stand-detail-delegates-bar-container{
    position:absolute;
    left:85px;
    top:200px;
    z-index:400;
    height:calc(100% - 370px);
    
    
}



.stand-detail-delegate-entry-container{
    height:100px;
    background-color:#ffffff;
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
    position:relative;
    margin-bottom:3px;
}

.stand-detail-delegate-photo-container{
    position:relative;
    float:left;
    height:100px;
    width:120px;
}



.stand-detail-delegate-photo{
    text-align:right;
    margin-top:10px;
    position:relative;
}


.stand-detail-delegate-photo img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.stand-detail-delegate-photo-time-container{
    position:absolute;
    bottom:8px;    
    right:25px;    
    background-color:#000000;
    color:#ffffff;
    text-align:center;
    font-family: MyriadPro;
    font-size:12px;
    padding:3px;
    border-radius:15px;
}




.stand-detail-delegate-name-container{
    position:relative;
    float:right;
    height:80px;    
    width:240px;
    padding-top:20px;
    font-family: MyriadPro;
    font-size:16px;
}


.stand-detail-delegate-contact-bottom-container{
    position:absolute;
    bottom:0px;
    left:0px;
    right:5px;
    width:auto;
    height:20px;    
}


.stand-detail-delegate-contact-bottom-container.person-time{
    position:absolute;
    bottom:0px;
    left:0px;
    right:5px;
    width:auto;
    height:30px;    
}


.person-time .person-detail-bottom-chat-opt-right-separator{
    position:relative;
    float:right;   
    height:15px;
    width:1px;
    background-color:#ffffff;
    margin-right:5px;
    margin-left:5px;
}


.person-time .person-detail-bottom-chat-opt-right{
    font-size:14px;
    font-weight:bold;
    line-height:1.9;
}


.stand-detail-delegate-contact-bottom-container .person-detail-bottom-chat-send-message-container{
    font-size:10px;
}

.stand-detail-delegates-left-content-container{
    position:relative;
    float:left;
    width:375px;
    overflow-y:auto;
    height:calc(100% - 10px);
    padding-left:5px;
    padding-right:10px;
    padding-top:5px;
    padding-bottom:5px;
    background-color:#fcfcfc;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    
}

.stand-detail-delegates-left-content-container::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.stand-detail-delegates-left-content-container::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.stand-detail-delegates-left-content-container::-webkit-scrollbar-thumb
{
	background-color: #4d4d4d;
}

.stand-detail-delegates-right-bar-open-close-container{
    position:relative;
    float:left;
    width:10px;
    height:100%;
    background-color:#676767;
    cursor:pointer;
    
}

.stand-detail-delegatest-bar-open-close-icon-container{
    position:absolute;
    top:0;
    bottom:0;
    left:10px;
    right:0;
    margin:auto;
    width:8px;
    height:40px;
    

}


.stand-detail-delegates-bar-header{
    background-color:#008457;
    height:80px;
    position:relative;
}

.stand-detail-delegates-bar-header-title-close-container{
    display:inline-block;    
    width:360px;
    margin-top:10px;
    padding-left:15px;
    padding-right:15px;
}


.stand-detail-delegates-bar-header-close-left-container{
    position:relative;
    float:left;    
    margin-top:5px;
    cursor:pointer;
}


.stand-detail-delegates-bar-header-title-right-container{
    position:relative;
    float:right;
    font-family: MyriadPro;
    font-size:28px;
    color:#ffffff;
}




.stand-detail-messages-bar-container{
    position:absolute;
    right:85px;
    top:200px;
    z-index:400;
    height:calc(100% - 370px);
    
    
}




.stand-detail-messages-bar-open-info-floating-container{
    position:absolute;
    right:0px;
    top:200px;
    width:80px;
    height:80px;
    background-color:#008457;
    z-index:400;
    cursor: pointer;
}




.stand-detail-messages-bar-header-close-right-container{
    position:relative;
    float:right;    
    margin-top:5px;
    cursor:pointer;
}


.stand-detail-messages-bar-header-title-left-container{
    position:relative;
    float:left;
    font-family: MyriadPro;
    font-size:28px;
    color:#ffffff;
}



.stand-detail-messages-bar-open-info-icon-container{
    width:40px;
    height:28px;
    position:absolute;
    background-image:url(/img/stand-detail-messages-bar-open-info-icon.png);
    background-position:center;
    background-repeat:no-repeat;
    background-size:contain;
    left:0px;
    right:0px;
    top:33px;
    margin-left:auto;
    margin-right:auto;
}

.stand-detail-messages-bar-header-tabs-container{
    position:absolute;
    bottom:1px;
    width:auto;
    left:0px;
    right:0px; 
}

.stand-detail-messages-bar-header-tab{
    position:relative;
    float:left;
    padding-left:10px;
    padding-right:10px;
    border-bottom:4px solid #008457;
    font-family: MyriadPro;
    font-size:16px;
    text-align:center;
    margin-left:15px;
    color:#ffffff;
    cursor:pointer;
}

.stand-detail-participants-bar-header-tab{
    position:relative;
    float:right;
    padding-left:10px;
    padding-right:10px;
    border-bottom:4px solid #008457;
    font-family: MyriadPro;
    font-size:16px;
    text-align:center;
    margin-right:15px;
    color:#ffffff;
    cursor:pointer;
}

.stand-detail-messages-bar-header-tab.active, .stand-detail-messages-bar-header-tab:hover{
    border-bottom:4px solid #ffffff;
}

.stand-detail-participants-bar-header-tab.active, .stand-detail-messages-bar-header-tab:hover{
    border-bottom:4px solid #ffffff;
}



.stand-detail-messages-entry-container{ 
    background-color:#e7e8e8;
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
    position:relative;
    margin-bottom:3px;
    cursor: pointer;
}

.stand-detail-messages-entry-container.expanded{
    background-color:#ffffff;
}

.stand-detail-messages-photo-container{
    position:relative;
    float:left;
    height:100px;
    width:100px;
}


.stand-detail-messages-photo{
    text-align:center;
    margin-top:10px;
    position:relative;
}


.stand-detail-messages-photo img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.stand-detail-messages-name-container{
    position:relative;
    float:right;
    height:70px;    
    width:265px;
    padding-top:10px;
    font-family: MyriadPro;
    font-size:14px;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.expanded .stand-detail-messages-name-container{
    overflow: initial;
    text-overflow:initial;
    position:relative;
    height:initial;
    padding-bottom:15px;
}


.stand-detail-messages-name-container p{
    margin-top:10px;
    margin-bottom:0px;
    color:#818282;
}

.stand-detail-messages-time{
    position:absolute;
    z-index:200;
    right:10px;
    top:10px;    
    color:#b3b4b4;
    font-family: MyriadPro;
    font-size:13px;
}


.stand-detail-messages-expand-icon-container{
    position:relative;
    float:right;    
    cursor:pointer;
    width:15px;
    height:6px;
    background-image:url(/img/stand-detail-messages-expand-icon-down.png);
}

.expanded .stand-detail-messages-expand-icon-container{   
    width:15px;
    height:6px;
    background-image:url(/img/stand-detail-messages-expand-icon-up.png);
}


.stand-detail-messages-contact-bottom-container{
    position:absolute;
    bottom:10px;
    left:0px;
    right:5px;
    width:auto;
    height:6px;    
}


.stand-detail-messages-entry-wrapper{
    display:inline-block;
}

.stand-detail-messages-new-circle-container{
    position:absolute;
    bottom:5px;
    width:10px;
    height:10px;
    background-color:#008457;
    left:0px;
    right:0px;
    margin-left:auto;
    margin-right:auto;
    border-radius:50%;
}


@media (max-width: 1250px) {

    .stand-detail-available-delegates-container{
        width:auto !important;
    }
}



.stand-detail-available-delegates-container{
    position:absolute;
    
    bottom:10px;
    left:0;
    right:0;
    margin:auto;
    width:650px;
    
    max-height: calc(100% - 200px);
    background-color:#f2f3f3;
    z-index:900;
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
    overflow-y:auto;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
}


.stand-detail-available-delegates-container::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.stand-detail-available-delegates-container::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.stand-detail-available-delegates-container::-webkit-scrollbar-thumb
{
	background-color: #4d4d4d;
}





.stand-detail-available-delegates-header-container{
    padding:10px;
    background-color:#138357;
    color:#ffffff;
    font-family: MyriadPro;
    font-size:24px;
    text-align:left;
    line-height:1;
}


.stand-detail-available-delegates-header-container .description{
    font-size:14px;
}


.stand-detail-available-delegates-close-icon{
    position:absolute;
    top:0;
    right:0px;
    width:25px;
    height:25px;
    background-color:#000000;
    z-index:800;
    background-image:url(/img/icon-close-login-panel.png);
    background-repeat:no-repeat;
    background-position:center;
    cursor:pointer;
}

.stand-detail-available-delegates-content-container{
    padding:10px;
    display:inline-block;
    width:auto;
}

.stand-detail-available-delegates-content-entry{
    position:relative;
    float:left;
    background-color:#ffffff;
    width:300px;
    height:120px;
    margin-left:10px;
    margin-top:6px;
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
}

.stand-detail-available-delegates-photo-container{
    width:100px;
    height:120px;    
    position:relative;
    float:left;

}

.stand-detail-available-delegates-photo{
    text-align:center;
    margin-top:10px;
}

.stand-detail-available-delegates-photo img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.stand-detail-available-delegates-photo img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border:6px solid #ffffff;
}

.stand-detail-available-delegates-photo.green-connected img{       
    border:6px solid #138357;
}

.stand-detail-available-delegates-photo.red-connected img{  
    border:6px solid #c1262c;
}

.stand-detail-available-delegates-name-container{
    position:relative;
    float:left;    
    height:100px;
    width:190px;
    padding-top:20px;
    font-family: MyriadPro;
    color: #191919;
    font-size:18px;
    margin-left:10px;
}


.stand-detail-available-delegates-name-container .division{
    font-size:13px;
}

.stand-detail-available-delegates-bottom-contact-container{
    position:absolute;
    height:25px;
    width:100%;    
    bottom:0;
    left:0;
}



.stand-detail-delegate-bottom-chat-opt-right{
    position:relative;
    float:left;    
    cursor:pointer;
}

.stand-detail-delegate-bottom-chat-opt-right-separator{
    position:relative;
    float:left;   
    height:20px;
    width:1px;
    background-color:#535353;
    margin-right:10px;
    margin-left:10px;
}













.stand-detail-hospitality-bar-open-info-floating-container{
    position:absolute;
    right:0px;
    top:280px;
    width:80px;
    height:80px;
    background-color:#008457;
    z-index:400;
    cursor: pointer;
}



.stand-detail-hospitality-bar-container{
    position:absolute;
    right:85px;
    top:280px;
    z-index:400;
    height:calc(100% - 370px); 
    
    
}


.stand-detail-hospitality-bar-open-info-icon-container{
    width:40px;
    height:33px;
    position:absolute;
    background-image:url(/img/stand-detail-hospitality-bar-open-info-icon.png);
    background-position:center;
    background-repeat:no-repeat;
    background-size:contain;
    left:0px;
    right:0px;
    top:33px;
    margin-left:auto;
    margin-right:auto;
}

.stand-hospitality-row-day{
    /*height: 100px;
    background-color: #c1262c;
    border-bottom: 2px solid #b9b9b9;*/
    display: inline-block;
    width: 100%;
    border-bottom: 2px solid #b9b9b9;
    padding-bottom: 5px;
}

.stand-hospitality-day{
    position: relative;
    float: left;
    height: 90px;
    width: 70px;
    background-color: #ffffff;
    font-family: MyriadPro;
    font-size:16px;
    text-align: center;
    padding: 5px;
}

.stand-hospitality-day-text{
    font-size: 40px;
}

.stand-hospitality-meeting-entry{
    position: relative;
    float: right;
    width: 285px;
    /*height: 100px;*/
    
}

.stand-hospitality-meeting{
    position: relative;
    float: left;
    width: 210px;
    /*height: 90px;*/
    padding: 5px;
    
    font-family: MyriadPro;
    font-size:14px;
    
}

.btn-start-meeting{
    
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #e7e7e7;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    float: left;
    font-size:13px;
    font-family: MyriadPro;
}

.btn-start-meeting.green{
    background-color: #059143;
}

.btn-start-meeting.red{
    background-color: #c12030;
}


.stand-hospitality-meeting-settings{
    position: relative;
    float: right;
    width: 65px;
    /*height: 90px;*/
}

.stand-hospitality-meeting-settings-icon-container{
    display: inline-block;
    width: 100%;
}

.stand-hospitality-meeting-settings-icon{
    text-align: right;
    position: relative;
    float: right;
    cursor: pointer;
}

.stand-hospitality-meeting-settings-persons{
    position: relative;
    margin-top: 30px;
    text-align: right;
    font-size:13px;
    font-family: MyriadPro;
}


.stand-hospitality-meeting-entry-wrapper{
    display: inline-block;
    border-bottom: 2px solid #b9b9b9;
    padding-bottom: 5px;
}

.stand-hospitality-meeting-entry-wrapper.last-meeting{
    border-bottom: 0px;
} 






.stand-detail-delegates-left-content-container.meeting{
    /* height:calc(100% - 80px);     */
}


.stand-hospitality-meeting-program-meeting-container{
    margin-top: 20px;
}

.btn-program-meeting{
    position: relative;
    background-color: #008457;
    color: #ffffff;
    font-size:14px;
    font-family: MyriadPro;
    text-align: center;
    width: 140px;
    padding: 5px;
    margin: auto;
    cursor: pointer;
}




.hospitality-schedule-container{
    position: absolute;
    margin: auto;
    width: 950px;
    /*height: 500px;*/
    background-color: #f2f2f2;
    left: 0px;
    right: 0px;    
    z-index: 1000;
}

.hospitality-schedule-container-wrapper{
    padding: 20px;
}



.hospitality-schedule-details{
    position: relative;
    float: left;
    width: 450px;
    /*height: 500px;*/
    /*background-color: #c1262c;*/
}


.hospitality-schedule-details-title-icon{
    position: relative;
    float: left;
}


.hospitality-schedule-details-title-text{
    position: relative;
    float: left;
    font-size:30px;
    font-family: MyriadPro;
    margin-left: 10px;
}

.hospitality-schedule-details-title-container{
    display: inline-block;
    width: 100%;
}




.hospitality-schedule-persons{
    position: relative;
    float: right;
    width: 410px;
    height: 500px;
    /*background-color: #059143;*/
}


.hospitality-schedule-details-title-icon.persons{
    position: relative;
    float: right;
}

.hospitality-schedule-details-title-text.persons{
    position: relative;
    float: right;
    font-size:30px;
    font-family: MyriadPro;
    margin-left: 10px;
    line-height: 3;
}



.hospitality-form-input input{
    width: 98%;
    border:0px;
    height: 18px;
    background-color: #ffffff;
}

.hospitality-form-input select{
    width: 98%;
    border:0px;
    height: 18px;
}

.hospitality-form-input input:focus{
    outline: none;
}

.hospitality-form-input select:focus{
    width: 98%;
    border:0px;
    height: 18px;
}

.hospitality-form-input-label{
    font-family: MyriadPro;
    font-size: 14px;
}

.hospitality-form-input-container{
    margin-top: 20px;
}


.hospitality-form-input-container.date{
    position: relative;
    float: left;
    width: 200px;
}

.hospitality-form-input-container.start{
    position: relative;
    float: left;
    width: 83px;
}

.hospitality-form-input-container.duration{
    position: relative;
    float: left;
    width: 83px;
}

.hospitality-form-input-container.zone{
    position: relative;
    float: left;
    width: 83px;
}


.hospitality-form-input-container-wrapper{
    display: inline-block;
    width: 100%;
}


.hospitality-form-password{
    border:0px;
    height: 18px;
    margin-left: 10px;
    background-color: #ffffff;
}

.hospitality-form-password:focus{
    outline: none;
}


.hospitality-form-input-container label{
    font-family: MyriadPro;
    font-size: 13px;
}


.hospitality-form-input-desc{
    margin-left: 20px;
    font-family: MyriadPro;
    font-size: 12px;
    margin-bottom: 5px;
}


.host-option{
    display: inline;
    margin-right: 6px;
}



.hospitality-form-input-container.host-options{
    position: relative;
    float: left;
    width: 150px;
}


.hospitality-form-delete-meeting-container{
    position: relative;
    float: left;
    width: 150px;
    height: 50px;
}


.hospitality-form-btns-meeting-container{
    position: relative;
    float: right;
    width: 300px;
    height: 50px;
    
}


.hospitality-form-btn{
    position: relative;
    float: right;
    width: 100px;
    border: 1px solid #000000;
    color: #000000;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}


.hospitality-form-btn.green{
    position: relative;
    float: right;
    width: 100px;
    background-color: #008457;
    color: #ffffff;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid #008457;
}



.hospitality-form-btn.delete{
    position: relative;
    float: left;
    width: 130px;
    background-color: #c1262c;
    color: #ffffff;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;
    margin-left: 0px;
    border: 1px solid #c1262c;
}


.hospitality-persons-tab-list-container{
    width: 100%;
    display: inline-block;
}


.hospitality-persons-tab{
    position: relative;
    float: left;
    border-top: 3px solid #f2f2f2;
    font-family: MyriadPro;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
    padding: 5px;
}


.hospitality-persons-tab:hover{
    border-top: 3px solid #008457;
}

.hospitality-persons-tab.selected{
    border-top: 3px solid #008457;
    font-weight: bold;
}

.hospitality-persons-count{
    position: relative;
    float: right;
    font-family: MyriadPro;
    font-size: 16px;
}


.hospitality-search-persons-container{
    display: inline-block;
    width: 100%;
}

.hospitality-search-persons-search-icon{
    position: relative;
    float: left;
    width: 30px;
    height: 20px;
    background-color: #ffffff;
    text-align: center;
    cursor: pointer;
}

.hospitality-search-persons-search-input{
    position: relative;
    float: left;
    background-color: #ffffff;
    width: 380px;
    height: 20px;
    
}

.search-persons{
    width: 98%;
    border: 0px;

}

.search-persons:focus{
    outline: none;
}


.hospitality-form-separator{
    position: relative;
    float: left;
    margin-left: 24px;
    width: 1px;
    height: 600px;
    background-color: #000000;
    margin-top: 75px;
}


.hospitality-persons-list-container{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    height: calc(100% - 100px);
    max-height: 500px;
    background-color: #ffffff;
}


.hospitality-persons-list-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .hospitality-persons-list-container::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .hospitality-persons-list-container::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }


.tbl-hospitality-persons-list{
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tbl-hospitality-persons-list td{
   border:0px;
   padding-top: 5px;
   padding-bottom: 5px;
}


  .tbl-hospitality-persons-list .pic img{
      max-height: 40px;
  }

  .tbl-hospitality-persons-list .pic{
      width: 50px;
      border-bottom: 1px solid #dadada;
  }

  .tbl-hospitality-persons-list .name{
    border-bottom: 1px solid #dadada;
}

  .tbl-hospitality-persons-list .check{
    width: 30px;
}

.tbl-hospitality-persons-list{
    font-family: MyriadPro;
    font-size: 13px;
}

.tbl-hospitality-persons-list .person-department{
    font-size: 12px;
}

.tbl-hospitality-persons-list .letter-separator{
    background-color: #f2f2f2;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

.tbl-hospitality-persons-list .letter{
    font-size: 16px;
    padding-left: 5px;
}

.tbl-hospitality-persons-list .invited{
    text-align: right;
    padding-right: 5px;
    color: #008357;
    font-size: 13px;
}

.hospitality-persons-select-all-container{
    margin-top: 30px;
    font-family: MyriadPro;
    font-size: 14px;
}


.hospitality-form-btn.border-green{
    position: relative;
    float: right;
    width: 100px;
    border: 1px solid #008357;
    color: #008357;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}



.hospitality-form-btn.border-red{
    position: relative;
    float: right;
    width: 100px;
    border: 1px solid #ff1d25;
    color: #ff1d25;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.hospitality-meet-preview-container{
    position: absolute;
    margin: auto;
    width: 700px;
    /*height: 500px;*/
    background-color: #f2f2f2;
    left: 0px;
    right: 0px;
    top:80px;
    padding: 10px;
    text-align: center;
       
    z-index: 1000;
}


.meet-preview .hospitality-schedule-details-title-icon img{
    max-height: 50px;
}

.meet-preview .hospitality-schedule-details-title-text{
    font-size: 20px;
}

.hospitality-schedule-details-title{
    position: relative;
    float: right;
    font-size: 50px;
    font-family: MyriadPro;
}

.hospitality-meet-preview-title{
    font-size: 16px;
    font-family: MyriadPro;
}

.hospitality-meet-preview-title .meet-title{
    font-size: 30px;
}

.hospitality-meet-preview-image{
    width: 280px;
    height: 150px;
    background-color:black;
    margin: auto;
    border-radius: 5px;
    border: 6px solid #00a99d;
    margin-top: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.hospitality-meet-preview-name{
    position: absolute;
    bottom: 20px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 5px;
    font-family: MyriadPro;
    font-size: 14px;
    border-radius: 5px;
}



.hospitality-meet-preview-settings{
    width: 120px;
    margin: auto;
    margin-top: 20px;
    display: inline-block;
   
}


.hospitality-meet-preview-btn-mic{
    width: 46px;
    height: 34px;
    text-align: center;
    position: relative;
    float: left;
    cursor: pointer;
}

.hospitality-meet-preview-btn-video{
    width: 46px;
    height: 34px;
    text-align: center;
    position: relative;
    float: right;
    cursor: pointer;
}


.hospitality-meet-preview-label-ready{
    font-family: MyriadPro;
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
}


.hospitality-meet-preview-action-btns-container{
    width: 250px;
    margin: auto;
    margin-top: 20px;
    display: inline-block;     
}


.hospitality-meet-preview-action-btn{
    position: relative;
    width: 100px;
    text-align: center;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;   
    cursor: pointer;
}


.hospitality-meet-preview-action-btn.start{
    background-color: #008357;
    border: 1px solid #008357;
    color: #ffffff;
    float: left;
}

.hospitality-meet-preview-action-btn.abort{    
    border: 1px solid #000000;
    color: #000000;
    float: right;
}


.hospitality-meet-preview-password-container{
    width: 250px;
    margin: auto;
    font-family: MyriadPro;
    font-size: 16px;
    margin-top: 20px;
}

.hospitality-meet-preview-password-container input{
    border:0px;
    background-color: #ffffff;
    width: 100%;
    text-align: center;
}


.hospitality-meet-preview-password-container input:focus{
    outline: none;
}


.hospitality-meeting-container{
    position: absolute;  
    width: 100%;
    height: 100%;   
    background-color: #1a1a1a;  
    z-index: 1000;
    top:0px;
    left: 0px;
    right:0px;
    bottom: 0px;
}

.sidebar-on .hospitality-meeting-container{
    width: 75%;
}

.hospitality-meeting-area{
    position: absolute;         
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
}


.hospitality-meeting-header{
    position: absolute;
    top: 0px;
    height: 80px;
    width: 100%;
    
}


.hospitality-meeting-header-logo{
    position: absolute;
    left: 0px;    
}

.hospitality-meeting-header-logo img{
    max-width: 200px;   
}

.hospitality-meeting-header-title{
    position: absolute;
    left: 0px;
    right: 0px;
    top:-40px;
    max-width: 700px;
    margin: auto;
    background-color: transparent;
    font-family: MyriadPro;
    /*font-size:22px;*/
    font-size: 2vw;
    color: #ffffff;
    text-align: center;
}

@media (min-width: 1250px) {

    .hospitality-meeting-header-title{
    font-size:22px;
    }

}


.hospitality-meeting-header-title .date{
    font-size:16px;
}

.hospitality-meeting-header-views{
    position: absolute;
    right: 0px;    
}


.hospitality-meeting-header-view{
    position: relative;
    float: left;
    margin-left: 10px;
    cursor: pointer;
}

.hospitality-meeting-header-view-label{
    margin-left: 10px;
    margin-bottom: 5px;
    font-family: MyriadPro;
    color: #ffffff;
    font-size: 13px;
}


.hospitality-meeting-footer{
    position: absolute;
    bottom: 0px;
    height: 65px;
    width: 100%;
    z-index: 1;
}


.hospitality-meeting-footer-comms-settings{
    position: absolute;
    left: 0px;   
    height: 80px;
}


.hospitality-meeting-footer-comms-settings-icon{
    position: relative;
    float: left;
    width: 86px;
    height: 63px;    
    margin-right: 40px;
    cursor: pointer;
}

.hospitality-meeting-footer-options{
    position: absolute;
    width: 380px;
    height: 63px;
    left: 0px;
    right: 0px;
    margin: auto;
}


.hospitality-meeting-footer-option-icon{
    position: relative;
    float: left;
    width: 86px;
    height: 63px;  
    margin-right: 20px;
    margin-left: 20px;
    cursor: pointer;
    
}



.hospitality-meeting-footer-actions{
    position: absolute;
    right: 0px;
    width: 300px;
    height: 63px;    
}

.hospitality-meeting-footer-action-icon{
    position: relative;
    float: right;
    width: 98px;
    height: 63px; 
    margin-left: 40px;
    cursor: pointer;
}


.hospitality-meeting-videos-grid-area{
    position: absolute;
    top:90px;
    bottom: 90px;
    left: 0px;
    right: 0px;
    width: auto;
    height: auto;    
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
}

.hospitality-meeting-videos-grid-area::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .hospitality-meeting-videos-grid-area::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .hospitality-meeting-videos-grid-area::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }


.hospitality-meeting-video-container-publisher{
    position: relative;
    float: left;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* width: 24%; */
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    /* height: 30%; */
    /*width: 25%;
    height: 33.3%;*/
    /* background-color: #c12030; */
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-origin: border-box;    
    border-radius: 5px;
    cursor: pointer;
    
}


.hospitality-meeting-video-container-publisher.host{
    border: 2px solid #01a99d;
}    
.hospitality-meeting-video-container-publisher.attendee{
    border: 2px solid #1a1a1a;
}    

.hospitality-meeting-video-container-publisher.speaking{
    border: 2px solid #feff00;
}    



.hospitality-meeting-video-container-subscriber{
    position: relative;
    /*float: left;*/
    display: inline-block;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* width: 24%; */
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    /* height: 30%; */
    /*width: 25%;
    height: 33.3%;*/
    /* background-color: #c12030; */
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-origin: border-box;    
    border-radius: 5px;
    cursor: pointer;
    
}

.hospitality-meeting-video-container-share{
    position: relative;
    float: left;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* width: 24%; */
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    /* height: 30%; */
    /*width: 25%;
    height: 33.3%;*/
    /* background-color: #c12030; */
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-origin: border-box;    
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid white;
    
}



.hospitality-meeting-video-container-subscriber.host{
    border: 2px solid #01a99d;
}    
.hospitality-meeting-video-container-subscriber.attendee{
    border: 2px solid #1a1a1a;
}

.hospitality-meeting-video-container-subscriber.speaking{
    border: 2px solid #feff00;
}  



.hospitality-meeting-video-mic{
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 40px;
    height: 38px;
    cursor: pointer;
   
}


.hospitality-meeting-video-ask-speak{
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40px;
    height: 38px;   
}


.hospitality-meeting-sidebar{
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0px;
    right: 0px;
    background-color: #383838;
    z-index: 1000;
    display: none;
    /*min-width: 400px;*/
}

.sidebar-on .hospitality-meeting-sidebar{
    display: block;
}

.hospitality-meeting-sidebar-tabs-container{
    display: inline-block;
    width: 100%;
    margin-top: 40px;
}

.hospitality-meeting-sidebar-tab{
    position: relative;
    float: left;
    width: 50%;    
    background-color:  #383838;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: MyriadPro;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}


.hospitality-meeting-sidebar-wrapper{
    padding: 20px;
}


.hospitality-meeting-sidebar-tab.waiting-room.selected{
    background-color:  #ff1b26;
}






.meeting-sidebar-waiting-room-container, .meeting-sidebar-participants-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.meeting-sidebar-waiting-room-entry-container{
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    
}

.meeting-sidebar-waiting-room-entry-pic{
    position: relative;
    float: left;
    width: 40px;
    height: 40px;
    margin-left: 10px;
}


.meeting-sidebar-waiting-room-entry-pic img{
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
}


.meeting-sidebar-waiting-room-entry-name{
    position: relative;
    float: left;    
    height: 40px;    
    margin-left: 10px;
    font-family: MyriadPro;
    color: #ffffff;
    font-size: 15px;
    line-height: 3;
}

.meeting-sidebar-waiting-room-btn{
    position: relative;
    float: right;
    margin-left: 10px;
    margin-right: 10px;
    width: 80px;
    font-family: MyriadPro;
    font-size: 14px;
    padding: 5px;   
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
}


.meeting-sidebar-waiting-room-btn.accept{
    background-color: #ffffff;
    border: 1px solid #00a99b;
    color: #00a99b;
}


.meeting-sidebar-waiting-room-btn.deny{
    background-color: #ffffff;
    border: 1px solid #ff1b26;
    color: #ff1b26;
}



.meeting-sidebar-waiting-room-btn.accept-all{
    background-color: #00a99b;
    border: 1px solid #00a99b;
    color: #ffffff;
}


.meeting-sidebar-waiting-room-btn.deny-all{
    background-color: #ff1b26;
    border: 1px solid #ff1b26;
    color: #ffffff;
}




.hospitality-meeting-sidebar-tab.participants.selected{
    background-color:  #5b5b5b;
}



.meeting-sidebar-participants-icon{
    position: relative;
    float: right;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    margin-top: 5px;
}


.meeting-sidebar-participants-icon.video{
    margin-top: 8px;
}


.meeting-sidebar-participants-settings-container{   
    display: inline-block;
    margin-top: 10px;
    width: 100%;
    border-top:1px solid #808080;    
    padding-top:10px;
}


.meeting-sidebar-participants-settings-icon{
    position: relative;
    float: left;
    width: 47px;
    height: 40px;
    margin-left: 10px;
    cursor: pointer;
}

.meeting-sidebar-participants-settings-mute-all-icon{
    position: relative;
    float: right;
    width: 46px;
    height: 38px;
    margin-right: 40px;
    cursor: pointer;
}


.meeting-sidebar-participants-settings-modal{
    position: absolute;
    padding: 5px;
    font-family: MyriadPro;
    font-size: 14px;
    color: #000000;
    background-color: #efefef;
    z-index: 200;
    left: 60px;
 
}



.hospitality-meeting-sidebar-tab-chat-container{
    display: inline-block;
    width: 100%;    
}



.meeting-sidebar-chat-container{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    height: 65%;
    max-height: 500px;  
    position: absolute;
    top:44px;
    left: 0px;
    right: 0px;    
    padding: 10px;
    margin: auto;    
    background-color: #5b5b5b;
}



.meeting-sidebar-chat-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .meeting-sidebar-chat-container::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .meeting-sidebar-chat-container::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }


  .meeting-sidebar-chat-input-container{
      position: absolute;
      width: 100%;
      height:calc(30% - 50px);
     
      bottom: 0px;
      left: 0px;
      right: 0px;
  }


  .meeting-sidebar-chat-to-container{
      position: relative;      
      height: 35%;
      padding-left: 10px;
      padding-right: 10px;      
      font-family: MyriadPro;
      font-size: 14px;
      color: #ffffff;
      /*border-bottom:1px solid #808080;*/
  }




.meeting-sidebar-chat-to-container select{
   
    border:0px;
    height: 18px;
    
    color: #000;
}

.meeting-sidebar-chat-to-container select:focus{
    outline: none;
}


  .meeting-sidebar-chat-to-input-container{
    position: relative;
    width: 100%;
    height: 65%;
    
}


.meeting-sidebar-chat-to-input{
    position: relative;
    float: left;
    width: 80%;
    height: 20px;
    margin-top: 5px;
    color: #ffffff;
   
    
}


.meeting-sidebar-chat-to-input input{
    background-color: transparent;
    border:0px;
    width: 98%;
    color: #ffffff;
}


.meeting-sidebar-chat-to-input input:focus{
    outline: none;
}


.meeting-sidebar-chat-to-btn{
    position: relative;
    float: right;
    width: 20%;
    text-align: right;
}

.meeting-sidebar-chat-to-btn img{
    cursor: pointer;
    max-height: 30px;
    margin-top: 3px;
}



.meeting-sidebar-participants-waiting-room-container{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    max-height: 500px;
    background-color: #ffffff;    
    background-color: #5b5b5b;
    position: absolute;
    top:104px;
    left: 20px;
    right: 20px;
    margin: auto;    
}

.meeting-sidebar-participants-waiting-room-section-2{
    height: calc(50% - 104px);
}

.meeting-sidebar-participants-waiting-room-section-1{
    height: calc(100% - 104px);
}

.meeting-sidebar-participants-waiting-room-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .meeting-sidebar-participants-waiting-room-container::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .meeting-sidebar-participants-waiting-room-container::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }






.hospitality-meeting-sidebar-participants-waiting-room{
    display: none;
}

.hospitality-meeting-sidebar.participants-waiting-room .hospitality-meeting-sidebar-participants-waiting-room{
    display: block;
}

.hospitality-meeting-sidebar-chat{
    position: absolute;
    width: auto;    
    left: 20px;
    right: 20px;
    margin: auto;
    display: none;
    
}

.hospitality-meeting-sidebar-chat-section-2{
    top:51%;
    height: 49%;
}

.hospitality-meeting-sidebar.chat .hospitality-meeting-sidebar-chat{
    display: block;
}





.hospitality-meeting-videos-grid-arrow{
    position: absolute;
    cursor: pointer;
    
}

.hospitality-meeting-videos-grid-arrow.left{    
    left: 10px;
    top: 50%;
}

.hospitality-meeting-videos-grid-arrow.right{    
    right: 10px;
    top: 50%;
}




.hospitality-meeting-videos-full-area{
    position: absolute;
    top:90px;
    bottom: 90px;
    left: 0px;
    right: 0px;
    width: auto;
    height: auto;    
   
}


.hospitality-meeting-full-video{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: auto;
    height: calc(100% - 120px);
    /* background-color: #808080; */
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-origin: border-box;    
    border-radius: 5px;
    /* border: 8px solid #feff00; */
    /* overflow:hidden; */
    z-index: 100;
}



.hospitality-meeting-full-video-persons-videos-area{
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: auto;
    height: 100px;
    
}


.hospitality-meeting-full-video-persons-host{
    position: relative;
    float: left;
    width: 180;
    height: 100px;
    background-color: #c1262c;
}




.hospitality-meeting-full-video-persons-container{
    position: relative;
    /* float: left; */
    /* width: calc(100% - 200px); */
    width: 100%;
    height: 100px;
    /* margin-left: 20px; */
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    white-space: nowrap;
    z-index: 50;
}

.hospitality-meeting-full-video-persons-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  .hospitality-meeting-full-video-persons-container::-webkit-scrollbar {
    height: 6px;
    background-color: #f5f5f5;
  }
  .hospitality-meeting-full-video-persons-container::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }


.hospitality-meeting-full-mini-video-persons{
    width: 14%;
    height: 85%;
    background-color:black;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    background-origin: border-box;
    border-radius: 5px; 
    position: relative;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}

.hospitality-meeting-full-mini-video-persons.host{
    border: 2px solid #08a99d;
    margin-left: 0px;
    margin-right: 0px;
    width: 138px;
    height: 100px;
}

.hospitality-meeting-full-mini-video-persons.attendee{
    border: 2px solid #1a1a1a;
    margin-left: 0px;
    margin-right: 0px;
    width: 138px;
    height: 100px;
}



.hospitality-meeting-full-mini-video-persons .hospitality-meet-preview-name{
    font-size: 10px;
    bottom:5px !important;
}


.hospitality-meeting-full-mini-video-persons .hospitality-meeting-video-mic{
    height: 20px;
    width: 21px;
    bottom:5px !important;
}

.hospitality-meeting-full-mini-video-persons .hospitality-meeting-video-mic img{
    font-size: 10px;
    max-height: 20px;
    max-width: 21px;
}


.hospitality-meeting-full-video-persons-arrow{
    position: relative;   
    height: 100px;
    width: 15px;
    cursor: pointer;
}

.hospitality-meeting-full-video-persons-arrow.left{    
    float: left;    
}

.hospitality-meeting-full-video-persons-arrow.right{    
    float: right;    
}


.arrow-mini-videos-container{
    position: absolute;
    width: 15px;
    height: 28px;
    top:0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
}


.hospitality-meeting-mini-videos-speakers-wrapper{
    height: 100px;
    width: auto;
    margin:0 auto;
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #4d4d4d #F5F5F5;
    white-space: nowrap;
}

.hospitality-meeting-mini-videos-speakers-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  
  .hospitality-meeting-mini-videos-speakers-wrapper::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  
  .hospitality-meeting-mini-videos-speakers-wrapper::-webkit-scrollbar-thumb {
    background-color: #4d4d4d;
  }

  .hospitality-meeting-hide{
      display:none;
  }
  .hospitality-meeting-show{
    display:block;
}

.hospitality-meeting-chat-message{
    color: #cccccc;
    font-family: MyriadPro;
    font-size:14px;   
}

.hospitality-meeting-chat-message-me{
    color: #01a99d;
    font-family: MyriadPro;
    font-size:14px;   
}