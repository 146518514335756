@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"), url(./fonts/MyriadPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Dotmatrx";
  src: local("Dotmatrx"), url(./fonts/Dotmatrx.ttf) format("truetype");
}

html,
body {
  height: 100%;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}

.full-container {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.avatar-profile {
  width: 120px;
  height: 140px;
  background-color: #01a89e;
  position: relative;
  float: left;
  text-align: center;
}

.div-img-avatar-wrapper {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 25px;
}

.img-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.div-name-wrapper {
  padding: 30px;
}

.name-profile {
  width: 280px;
  height: 140px;
  background-color: #00665b;
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #ffffff;
}

.name-profile p {
  margin-top: 0px;
  margin-bottom: 5px;
}

.p-profile-name {
  font-size: 28px;
}

.menu-container {
  position: relative;
  float: left;
  background-color: #000000;
  width: 100%;
  /*height:80px;*/
}

.menu-items-wrapper {
  margin-left: 400px;
  height: 60px;
  padding-top: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 10px;
}

.menu-item {
  text-align: center;
  position: relative;
  float: left;
  width: 7%;
  margin-right: 4%;
  font-size: 13px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 7px;
  height: 63px;
  min-width: 76px;
  font-family: "MyriadPro";
}

.menu-item a {
  color: #26a9b3;
  text-decoration: none;
  font-weight: bold;
}

.menu-item.active a {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.menu-item img {
  max-height: 30px;
}

.menu-item.active {
  color: #ffffff;
  background-color: #26a9b3;
}

.search-logout-wrapper {
  position: absolute;
  /* width: 400px;*/
  height: 80px;
  right: 0px;
  top: 0px;
  z-index: 100;
  /*background-color: #000000;*/
  background-color: transparent;
}

.search-input-wrapper {
  position: relative;
  float: right;
  margin-top: 20px;
  margin-right: 10px;
}

.search-btn {
  position: relative;
  float: left;
}

.search-btn img {
  max-height: 27px;
}

.search-input {
  position: relative;
  float: left;
}

.search-input input {
  height: 25px;
  width: 250px;
  border: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: 10px;
}

@media (max-width: 1800px) {
  .search-input input {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .search-input input {
    width: 150px;
  }
}

@media (max-width: 1400px) {
  .search-input input {
    width: 100px;
  }
}

.search-input input:focus {
  outline: none;
}

.logout-wrapper {
  position: relative;
  float: right;
  margin-top: 20px;
  margin-right: 30px;
  text-align: center;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 12px;
}

.logout-wrapper img {
  max-height: 25px;
  cursor: pointer;
}



.profile-menu-item {
  text-align: center;
  padding: 15px;
  background-color: rgba(0, 102, 91, 0.8);
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 14px;
}

.profile-menu-item img {
  max-height: 50px;
}

.profile-menu-item-separator {
  height: 2px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 20px;
}

.live-sessions-container {
  position: relative;
  float: left;
  background-color: #3f3c39;
  width: 100%;
}

.live-sessions-wrapper {
  margin-left: 400px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
}

.live-info {
  position: relative;
  float: left;
  margin-top: 5px;
  background-image: url(/img/live-no-letters2.png);
  width: 86px;
  height: 25px;
  text-align: center;
  padding-top: 5px;
}

.live-info .live-text {
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 18px;
  font-weight: bold;
}

.live-info img {
  max-height: 30px;
}

.live-sessions-separator {
  position: relative;
  float: left;
  width: 2px;
  height: 45px;
  background-color: #a09f9d;
  margin-left: 15px;
  margin-right: 15px;
}

.live-session-info {
  position: relative;
  float: left;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 13px;
  margin-right: 35px;
  max-width: 180px;
  overflow: hidden;
  height: 50px;
}

.live-session-info.last {
  margin-right: 0px !important;
}

@media (max-width: 1800px) {
  .live-session-info.info4 {
    display: none;
  }
}

@media (max-width: 1600px) {
  .live-session-info.info3 {
    display: none;
  }
}

@media (max-width: 1400px) {
  .live-session-info.info2 {
    display: none;
  }
}

.right-info-container {
  position: absolute;
  right: 0px;
  /*width:500px;*/
  height: 60px;
  top: 0px;
  padding-right: 20px;
  background-color: #3f3c39;
}

.right-info-wrapper {
  position: relative;
  float: right;
  height: 65px;
}

.right-info-item {
  /* width: 150px; */
  height: 50px;
  text-align: center;
  padding: 5px;
  cursor: pointer;
}

.right-info-item.selected {
  background-color: #00a99d;
}

.right-info-item img {
  max-height: 35px;
  /* max-height: 35px; */
  max-width: 150px;
}

.span-number {
  display: inline-block;
  min-width: 10px;
  padding: 3px 5px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #ff1d25;
  border-radius: 50%;
  font-family: "MyriadPro";
}

.number-wrapper {
  text-align: left;
  /*text-align: center;*/
}

.number-wrapper .span-number {
  margin-left: 50px;
}

/* .number-wrapper.number-my-bag {
  text-align: left;
} */

.number-wrapper.number-my-bag .span-number {
  /*margin-left: 30px;*/
  margin-left: 25px;
}

.right-info-icon-wrapper {
  text-align: center;
}

.item-my-bag {
  position: relative;
  float: left;
}

.left-wrapper-icon {
  width: 75px;
  margin-top: 15px;
  position: relative;
  float: left;
  text-align: right;
}

.right-wrapper-icon-text {
  width: 70px;
  margin-top: 15px;
  position: relative;
  float: left;
  text-align: left;
  padding-left: 5px;
  color: #05a196;
  text-decoration: underline;
  font-family: "MyriadPro";
  font-size: 13px;
}









.main-screen-bottom {
  width: 458px;
  height: 70px;
  background-image: url(/img/main-screen-bottom.png);
}

.main-screen-bottom-text {
  position: relative;
  float: left;
  color: #ffffff;
  font-family: "MyriadPro";
  padding-top: 8px;
  padding-left: 10px;
}

.main-screen-bottom-logo {
  position: relative;
  float: right;
  padding-top: 8px;
  padding-right: 10px;
}

.main-screen-bottom-logo img {
  max-height: 50px;
}


.logo-main-screen-bottom-block {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
}


.logo-main-screen-bottom-left {
  position: relative;
  float: left;
}

.logo-main-screen-bottom-right {
  position: relative;
  float: right;
}

.main-screen-bottom.separ2020 {
  width: 438px;
  /*height: 50px;*/
  background-image: none;
  background-color: #ffffff;
  ;
  padding: 10px;
}


.main-screen-bottom.psoe2021 {
  width: 100%;
  /*height: 50px;*/
  background-image: none;
  background-color: transparent;

}

.main-screen-bottom.vitdah span:hover {
  background-color: #99c65a;
}

.main-screen-bottom.vitdah p {
  margin-top: 0px;
  margin-bottom: 10px;
}

.main-screen-bottom.vitdah a {
  text-decoration: none;
}


.main-screen-bottom.urovi2021 span:hover {
  background-color: #048e53;
}

.main-screen-bottom.sea2021 span:hover {
  background-color: #882bc6;
}

.main-screen-bottom.urovi2021 p {
  margin-top: 0px;
  margin-bottom: 10px;
}

.main-screen-bottom.urovi2021 a {
  text-decoration: none;
}


.main-screen-bottom.sea2021 p {
  margin-top: 0px;
  margin-bottom: 10px;
}

.main-screen-bottom.sea2021 a {
  text-decoration: none;
}




main-screen-bottom.vpracticvm span:hover {
  background-color: #99c65a;
}

.main-screen-bottom.vpracticvm p {
  margin-top: 0px;
  margin-bottom: 10px;
}

.main-screen-bottom.vpracticvm a {
  text-decoration: none;
}


.psoe2021 .main-screen-bottom-full-btn {
  width: 438px;
  background-color: #e30615;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  font-size: 20px;
  font-family: "MyriadPro";
  display: inline-block;
  text-decoration: none;
  text-align: center;

  border-radius: 5px;
  font-weight: bold;
}

.psoe2021 .main-screen-bottom-full-btn:hover {
  background-color: #cd0a17;
}

.left-screen-wrapper {
  width: 300px;
  /*position: relative;
  float: left;*/
  position: absolute;
  left: 0px;
  z-index: 900;
}

.top-screen-bar {
  width: 300px;
  height: 10px;
  background-color: #4d4d4d;
}





.right-screen-wrapper {
  width: 300px;
  /*position: relative;
  float: left;*/
  position: absolute;
  right: 0px;
  z-index: 900;
}

.profile-menu-area-container {
  position: absolute;
  left: 120px;
  top: 140px;
  width: 840px;
  height: calc(100% - 200px);
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #ffffff;
  z-index: 1000;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.selected-menu-item-arrow.my-profile {
  position: absolute;
  right: 0px;
  top: 40px;
}

.selected-menu-item-arrow.my-schedule {
  position: absolute;
  right: 0px;
  top: 150px;
}

.selected-menu-item-arrow.my-bag {
  position: absolute;
  right: 0px;
  top: 280px;
}

.selected-menu-item-arrow.my-credits {
  position: absolute;
  right: 0px;
  top: 400px;
}

.selected-menu-item-arrow img {
  max-height: 35px;
}

.navigation-panel-container {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.navigation-panel-entry {
  background-color: #ffffff;
  color: #000000;
  font-family: "MyriadPro";
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #000000;
}

.navigation-panel-entry-text {
  max-width: 280px;
}

.navigation-panel-entry-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.navigation-panel-bar {
  width: 330px;
  height: 30px;
  background-image: url(/img/navigation-panel-bottom-background.png);
  padding: 10px;
  border-radius: 5px;
}

.navigation-panel-text {
  position: relative;
  float: left;
  color: #ffffff;
  font-family: "MyriadPro";
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 5px;
}

.navigation-panel-icon {
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 5px;
}

.navigation-panel-icon img {
  max-width: 30px;
}

.navigation-panel {
  width: 350px;
  height: 280px;
  background-color: #e5e1de;
}

.navigation-location-container {
  height: 20px;
  padding: 10px;
}

.navigation-location-wrapper {
  position: relative;
  float: right;
  width: 140px;
  height: 20px;
  border: 1px solid #cdccca;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
}

.navigation-location-icon {
  position: relative;
  float: left;
}

.navigation-location-icon img {
  max-height: 15px;
  margin-top: 2px;
}

.navigation-location-text {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  font-size: 13px;
  margin-top: 3px;
}

.navigation-floors {
  position: relative;
  float: left;
  width: 110px;
  padding-left: 15px;
  padding-right: 15px;
}

.navigation-floors-container {
  background-color: #1a1a1a;
  width: 100%;
  margin-top: 40px;
}

.navigation-floors-map {
  position: relative;
  float: left;
  width: 210px;
  text-align: center;
  margin-top: 5px;
}

.navigation-floors-map img {
  max-width: 200px;
  max-height: 220px;
}

.navigation-floors-arrow-up {
  width: 100%;
  text-align: center;
  padding-top: 5px;
}

.navigation-floors-arrow-up img {
  max-height: 10px;
}

.navigation-floors-arrow-down {
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
}

.navigation-floors-arrow-down img {
  max-height: 10px;
}

.navigation-floors-scroll {
  font-family: "Dotmatrx";
  font-size: 9px;
  color: #ffffff;
  padding: 10px;
}

.navigation-floors-scroll .selected {
  color: #ffff00;
}





.virtual-assitant-logo-left {
  position: relative;
  float: left;
}

.virtual-assitant-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.virtual-assitant-separator {
  width: 1px;
  height: 30px;
  background-color: #868686;
  position: relative;
  float: left;
  margin-left: 10px;
  margin-right: 10px;
}

.virtual-assitant-logo-right {
  position: relative;
  float: right;
}

.virtual-assitant-logo-right img {
  max-height: 30px;
}

.chat-container {
  position: absolute;
  right: 20px;
  bottom: 10px;
}

.chat-bar {
  width: 260px;
  height: 30px;
  padding: 5px;
  background-color: #bebec2;
  border-radius: 5px;
}

.icon-chat {
  position: relative;
  float: left;
  margin-top: 2px;
  margin-left: 5px;
}

.icon-chat img {
  max-height: 25px;
}

.chat-bar-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 12px;
  margin-top: 10px;
  margin-left: 5px;
}

.chat-settings,
.chat-write,
.chat-add {
  position: relative;
  float: right;
  margin-right: 10px;
}

.right-info-more {
  position: absolute;
  float: right;
  width: 310px;
  background-color: rgba(242, 242, 242, 0.9);
  top: 140px;
  right: 20px;
  z-index: 950;
  border-top: 4px solid #00a99d;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.right-info-more::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.right-info-more::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.right-info-more::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.right-info-more-title {
  position: relative;
  float: left;
  font-size: 19px;
  font-family: "MyriadPro";
  font-weight: bold;
  margin-left: 19px;
}

.right-info-more-close {
  position: relative;
  float: right;
  margin-top: 5px;
}

.right-info-more-close img {
  max-height: 10px;
}

.right-info-more-wrapper {
  width: 100%;
  height: 35px;
}

.info-wrapper {
  width: 100%;
}

.circle-info {
  position: relative;
  float: left;
  width: 20px;
  height: 45px;
  text-align: center;
}

.span-circle {
  height: 10px;
  width: 10px;
  background-color: #00a99d;
  border-radius: 50%;
  display: inline-block;
  margin-top: 15px;
}

.info-text {
  width: 250px;
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 14px;
  padding: 4px;
}

.info-text .sub-text {
  color: #666666;
  font-size: 12px;
}

.info-text-time {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  font-size: 12px;
  color: #666666;
}

.info-separator {
  width: 285px;
  height: 2px;
  background-color: #717272;
  position: relative;
  float: left;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}



.top-left-mainscreen {
  position: relative;
  float: left;
  height: 30px;
  width: 60px;
  text-align: right;
  border-right: 3px solid #4d4d4d;
}

.top-right-mainscreen {
  position: relative;
  float: right;
  height: 30px;
  width: 60px;
  text-align: left;
  border-left: 3px solid #4d4d4d;
}

.top-sidescreen-wrapper {
  height: 80px;
  width: 300px;
}

.top-left-sidescreen {
  position: relative;
  float: left;
  height: 80px;
  width: 60px;
  text-align: right;
  border-right: 1px solid #4d4d4d;
}

.top-right-sidescreen {
  position: relative;
  float: right;
  height: 80px;
  width: 60px;
  text-align: left;
  border-left: 1px solid #4d4d4d;
}

.sefc2022 .preview-dark,
.SEFC2022 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/sefc2022/lobby/sefc2022-lobby-back.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}



.seaic2021 .preview-dark,
.SEAIC2021 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/seaic2021/lobby/seaic2021-lobby-back.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}


.lahrs2021 .preview-dark,
.LAHRS2021 .preview-dark {
  background-color: #ffffff;
}

.aea-serod2021 .preview-dark,
.AEA-SEROD2021 .preview-dark {
  background-color: #ffffff;
}

.neumomadrid2021 .preview-dark,
.NEUMOMADRID2021 .preview-dark {
  background-color: #ffffff;
}


.secec2021 .preview-dark,
.SECEC2021 .preview-dark {
  background-color: #ffffff;
}


.cuenca2021 .preview-dark,
.CUENCA2021 .preview-dark {
  background-color: #ffffff;
}


.eacmfs2021 .preview-dark,
.EACMFS2021 .preview-dark {
  background-color: #ffffff;
}


.semcpt2021 .preview-dark,
.SEMCPT2021 .preview-dark {
  background-color: #0e0f23;
}

.seavirtual2021 .preview-dark,
.SEAVIRTUAL2021 .preview-dark {
  background-color: #ffffff;
}

.ests2021 .preview-dark,
.ESTS2021 .preview-dark {
  background-color: #ffffff;
}


.semnim2021 .preview-dark,
.SEMNIM2021 .preview-dark {
  background-color: #ffffff;
}

.vpracticvm .preview-dark,
.VPRACTICVM .preview-dark {
  background-color: #ffffff;
}

.seip2021 .preview-dark,
.SEIP2021 .preview-dark {
  background-color: #ffffff;
}

.psoe .preview-dark,
.PSOE .preview-dark {
  background-color: #ffffff;
}


.encuentrosexperto2021 .preview-dark,
.ENCUENTROSEXPERTO2021 .preview-dark,
.tromboencuentros2021 .preview-dark,
.TROMBOENCUENTROS2021 .preview-dark {
  /*background-image:url(https://www.emma.events/static/virtualvenue/encuentrosexperto2021/lobby/encuentrosexperto2021-video-lobby.jpg);*/
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  font-family: "MyriadPro";
  font-size: 23px;
  color: #009b67;
}

.encuentrosexperto2021 .preview-dark p,
.ENCUENTROSEXPERTO2021 .preview-dark p,
.tromboencuentros2021 .preview-dark p,
.TROMBOENCUENTROS2021 .preview-dark p {
  padding: 15px;
  line-height: 1.3;
}

.\32 reunionvirtualareas2021 .preview-dark,
.\32 REUNIONVIRTUALAREAS2021 .preview-dark {
  background-color: #14124f;
}




.VI-AmpliandoHorizontes .preview-dark,
.VI-AMPLIANDOHORIZONTES .preview-dark,
.vi-ampliandohorizontes .preview-dark {
  /*background-image:url(https://www.emma.events/static/virtualvenue/encuentrosexperto2021/lobby/encuentrosexperto2021-video-lobby.jpg);*/
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  font-family: "MyriadPro";
  font-size: 23px;
  color: #009b67;
}

.VI-AmpliandoHorizontes .preview-dark p,
.VI-AMPLIANDOHORIZONTES .preview-dark p,
.vi-ampliandohorizontes .preview-dark p {
  padding: 15px;
  line-height: 1.3;
}



.viicursotdah2022 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/viitdah2022/lobby/viicursotdah2022-lobby-img-bck.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.neumomadrid2020 .preview-dark,
.NEUMOMADRID2020 .preview-dark {
  background-image: url(/img/neumomadrid2020-lobby-video-background.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}


.isin2021 .preview-dark,
.ISIN2021 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/isin2021/lobby/isin2021-lobby-center-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
}

.cnp2020 .preview-dark,
.CNP2020 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/cnp2020/lobby/cnp2020-videoCenter.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.cnp2021 .preview-dark,
.CNP2021 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/cnp2021/lobby/cnp2021-lobby-video.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.cnp2022 .preview-dark,
.CNP2022 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/cnp2022/lobby/cnp2022-lobby-video.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.cnp2023 .preview-dark,
.CNP2023 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/cnp2023/lobby/cnp2023-lobby-video.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.cnp2024 .preview-dark,
.CNP2024 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/cnp2024/lobby/cnp2024-lobby-video.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.separ2020 .preview-dark,
.SEPAR2020 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/separ2020/lobby/separ2020-videoCenter.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}


.separ2021 .preview-dark,
.SEPAR2021 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/separ2021/lobby/separ2021-lobby-video.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}


.portdebarcelona2021 .preview-dark,
.PORTDEBARCELONA2021 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/portdebarcelona2021/lobby/area_central_video.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}


.\32 congresocovid .preview-dark,
.\32 CONGRESOCOVID .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/2congresocovid/lobby/lobby-img-center.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
}

.\31 reunionvirtualareas2021 .preview-dark,
.\31 REUNIONVIRTUALAREAS2021 .preview-dark {
  /*background-image:url(https://www.emma.events/static/virtualvenue/1reunionvirtualareas2021/lobby/1reunionvirtualareas2021-lobby-center.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;*/
  background-color: #13104e;
}

.\33 reunionvirtualareas2022 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/3reunionvirtualareas2022/lobby/lobby-img-center-noplay.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #13104e;
  background-position: center;
}


.\34 reunionvirtualareas2022 .preview-dark {
  background-image: url(https://www.emma.events/static/virtualvenue/4reunionvirtualareas2022/lobby/lobby-video-4reunionvirtualareas2022.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #13104e;
  background-position: center;
}


.preview-dark img {
  margin-top: 80px;
}

.backdrop-virtual-assistant {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
}

.virtual-assistant-chat-container {
  bottom: 0;
  height: 650px;
  width: 400px;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.virtual-assistant-chat-header {
  height: 30px;
  background-color: #333333;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.virtual-assistant-icon-network {
  position: relative;
  float: left;
  margin-top: 5px;
}

.virtual-assistant-sponsored-text {
  position: relative;
  float: left;
  margin-top: 10px;
  font-family: "MyriadPro";
  font-size: 12px;
  color: #ffffff;
  margin-left: 5px;
}

.virtual-assistant-company-logo {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}

.virtual-assistant-company-logo img {
  max-height: 25px;
}

.virtual-assistant-close-icon {
  position: relative;
  float: right;
  margin-top: 10px;
}

.virtual-assistant-chat-avatar {
  position: relative;
  float: left;
}

.virtual-assistant-chat-avatar img {
  border-radius: 50%;
  max-height: 28px;
}

.virtual-assistant-chat-avatar-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 15px;
  margin-left: 5px;
}

.virtual-assistant-chat-avatar-text .virtual-assistant-name {
  font-weight: bold;
  color: #424242;
}

.virtual-assistant-chat-avatar-text .virtual-assistant-desc {
  color: #909090;
}

.virtual-assistant-chat-video-icon {
  position: relative;
  float: right;
  margin-top: 10px;
  margin-right: 20px;
}

.virtual-assistant-chat-video-icon img {
  max-height: 20px;
}

.virtual-assistant-chat-call-icon {
  position: relative;
  float: right;
  margin-top: 10px;
}

.virtual-assistant-chat-call-icon img {
  max-height: 20px;
}

.virtual-assistant-chat-subheader {
  height: 35px;
  background-color: #f2f2f2;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5d5d5;
}

.virtual-assistant-chat-area {
  height: calc(70vh - 100px);
  max-height: 500px;
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.virtual-assistant-chat-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.virtual-assistant-chat-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.virtual-assistant-chat-area::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.virtual-assistant-chat-footer {
  height: 35px;
  background-color: #f2f2f2;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #d5d5d5;
}

.virtual-assistant-chat-input {
  position: relative;
  float: left;
}

.virtual-assistant-chat-input input {
  height: 25px;
  width: 280px;
  border-radius: 5px;
  padding-left: 10px;
  border: 2px solid #d3d3d7;
}

.virtual-assistant-chat-input input:focus {
  outline: none;
}

.virtual-assistant-chat-send-button {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
}

/*.virtual-assistant-chat-send-button button{
  background-color:#00a99d;
  color:#ffffff;
  border:0px;
  border-radius: 5px;
  padding:5px;
  height:30px;  
}

.virtual-assistant-chat-send-button button:focus{
  outline: none;
}*/

.virtual-assistant-chat-send-photo {
  position: relative;
  float: right;
  margin-top: 10px;
  margin-right: 15px;
}

.virtual-assistant-chat-send-photo img {
  max-height: 20px;
}

.virtual-assistant-chat-send-attach {
  position: relative;
  float: right;
  margin-top: 5px;
}

.virtual-assistant-chat-send-attach img {
  max-height: 25px;
}

.assistant-chat-text {
  position: relative;
  float: left;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 15px;
  background-color: #ffffff;
  max-width: 280px;
}

.person-chat-text {
  position: relative;
  float: right;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 15px;
  background-color: #e7fbec;
  max-width: 280px;
}

.person-chat-text:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.virtual-assistant-video-call-video-assitant {
  height: 305px;
}

.virtual-assistant-video-call-video-person {
  height: 305px;
}

.virtual-assistant-call-footer {
  position: absolute;
  bottom: 0px;
  width: 370px;
  height: 80px;
  background-image: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1));
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 100;
}

.virtual-assistant-call-mute {
  position: relative;
  float: left;
  margin-top: 5px;
}

.virtual-assistant-call-change-type {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 30px;
}

.virtual-assistant-call-video-close {
  position: relative;
  float: right;
  margin-top: 5px;
  margin-left: 30px;
}

.virtual-assistant-phone-call {
  width: 400px;
  height: 610px;
  background-color: #00a99d;
  text-align: center;
}

.virtual-assistant-phone-call img {
  margin-top: 200px;
}

.chat-side-bar {
  position: absolute;
  bottom: 55px;
  right: 20px;
  width: 240px;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chat-side-bar-header-title {
  font-family: "MyriadPro";
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  position: relative;
  float: left;
}

.chat-side-bar-header-add-people {
  position: relative;
  float: right;
}

.chat-side-bar-header-add-people img {
  max-height: 30px;
}

.chat-side-bar-header {
  height: 30px;
}

.chat-person-pic {
  position: relative;
  float: left;
  height: 70px;
  margin-left: 10px;
}

.chat-person-pic img {
  border-radius: 50%;
  max-height: 70px;
}

.chat-person-pic-checked {
  position: absolute;
  right: 15px;
  bottom: 0px;
  width: 15px;
  height: 13px;
}

.chat-person-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #000000;
  margin-left: 10px;
  margin-top: 20px;
}

.chat-person-online {
  position: relative;
  float: left;
  margin-top: 20px;
}

.chat-person-wrapper {
  height: 70px;
  margin-bottom: 20px;
}

.chat-groups-container {
  position: absolute;
  bottom: 10px;
  width: 240px;
}

.chat-side-bar-header-separator {
  height: 1px;
  background-color: #4d4d4d;
  margin-bottom: 5px;
}

.lock-chat-group {
  position: relative;
  float: left;
  margin-top: 10px;
  margin-left: 5px;
}

.lock-chat-group img {
  max-height: 13px;
}

.group-chat-pic {
  position: absolute;
}

.group-chat-pic img {
  border-radius: 50%;
  max-height: 40px;
}

.group-chat-pic.pic1 {
  z-index: 50;
  left: 0px;
}

.group-chat-pic.pic2 {
  z-index: 100;
  left: 15px;
}

.group-chat-pic.pic3 {
  z-index: 150;
  left: 35px;
}

.chat-group-pic {
  position: relative;
  float: left;
  height: 70px;
  width: 70px;
  margin-left: 5px;
}

.chat-group-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #000000;
  margin-left: 10px;
  margin-top: 10px;
}

.chat-side-bar-header-group {
  height: 60px;
}

.active-chats-container {
  position: absolute;
  bottom: 10px;
  right: 290px;
}

.header-chat-window-closed {
  position: relative;
  float: right;
  width: 280px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #00a99d;
  border-radius: 5px;
  margin-right: 5px;
}

.header-chat-window-pic {
  position: relative;
  float: left;
}

.header-chat-window-pic img {
  border-radius: 50%;
  max-height: 30px;
}

.header-chat-window-peson-name {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #ffffff;
  margin-left: 5px;
}

.icon-chat-window-video {
  position: relative;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

.icon-chat-window-close {
  position: relative;
  float: right;
  margin-top: 5px;
}

.window-chat-wrapper {
  position: relative;
  float: right;
  width: 300px;
  margin-right: 5px;
  min-height: 360px;
}

.header-chat-window {
  position: relative;
  float: right;
  width: 280px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #00a99d;
  border-radius: 5px;
}

.header-chat-window.closed {
  position: absolute;
  bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #00a99d;
  border-radius: 5px;
}

.chat-area {
  height: 250px;
  max-height: 250px;
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.chat-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-area::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.chat-footer {
  background-color: #f2f2f2;
  height: 50px;
}

.chat-input {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}

.chat-input input {
  height: 25px;
  width: 180px;
  border-radius: 5px;
  border: 0px;
  background-color: #f2f2f2;
}

.chat-input input:focus {
  outline: none;
}

.chat-msg-receiver-pic {
  position: relative;
  float: left;
}

.chat-msg-receiver-pic img {
  border-radius: 50%;
  max-height: 30px;
}

.chat-msg-receiver-text {
  position: relative;
  float: left;
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  background-color: #f2f2f2;
  max-width: 264px;
  margin-left: 5px;
}

.chat-msg-sender-text {
  position: relative;
  float: right;
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  background-color: #cceeeb;
  max-width: 264px;
  margin-right: 5px;
}

.chat-msg-sender-pic {
  position: relative;
  float: right;
}

.chat-msg-sender-pic img {
  border-radius: 50%;
  max-height: 30px;
}

.chat-text-separator {
  width: 260px;
  height: 10px;
  position: relative;
  float: right;
}

.chat-people-area {
  height: 480px;
  max-height: 460px;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.chat-people-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-people-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-people-area::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.chat-group-area {
  height: 170px;
  max-height: 170px;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.chat-group-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.chat-group-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.chat-group-area::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.congress-name-date {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #4d4d4d;
}

.congress-name-date .congress-name {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.congress-name-date .congress-date {
  font-size: 12px;
}

.company-logo {
  float: right;
  position: relative;
}

.company-logo img {
  max-height: 50px;
}

.company-logo p {
  margin: 0px;
  font-family: "MyriadPro";
}

.menu-tab-item {
  position: relative;
  float: left;
  border-bottom: 2px solid transparent;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
  margin-top: 65px;
  margin-right: 20px;
}

.menu-tab-item.selected {
  border-bottom: 2px solid #01a8a1;
}

.tab-items-received {
  position: relative;
  float: right;
  text-align: center;
  font-family: "MyriadPro";
  line-height: 0.9;
  margin-right: 20px;
  margin-top: 10px;
}

.tab-items-received .number {
  color: #00a6a4;
  font-size: 40px;
  font-weight: bold;
}

.tab-items-received .items {
  color: #000000;
  font-size: 20px;
}

.tab-items-received .received {
  color: #000000;
  font-size: 13px;
}

.tabs-my-bag {
  width: 820px;
  height: 450px;
  /* max-height: 450px; */
  height: calc(100% - 150px);
  background-color: #ffffff;
  margin-top: 5px;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.tabs-my-bag::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.tabs-my-bag::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.tabs-my-bag::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.tab-insert-content-wrapper {
  width: 390px;
  height: 215px;
  position: relative;
  float: left;
  margin-top: 10px;
  border-bottom: 1px solid #e7e7e7;
}

.tab-insert-content-row {
  width: 100%;
  height: 250px;
  margin-top: 10px;
}

.tab-insert-content-wrapper-separator {
  position: relative;
  float: left;
  width: 10px;
  height: 215px;
}

.flyer-container {
  width: 130px;
  height: 185px;
  position: relative;
  float: left;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}



.flyer-text-container {
  width: 250px;
  height: 175px;
  position: relative;
  float: left;
  padding: 5px;
}

.flyer-company-logo-container {
  text-align: right;
  height: 30px;
}

.flyer-company-logo-container img {
  max-height: 30px;
  max-width: 150px;
}

.flyer-text-wrapper {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  margin-top: 15px;
  height: 135px;
  max-height: 135px;
  /* overflow: hidden; */
  padding-left: 5px;
  padding-right: 5px;
}

.flyer-text-wrapper a {
  color: #16a796;
  font-weight: bold;

}

.flyer-download-container {
  width: 130px;
  position: relative;
  float: left;
  text-align: center;
  font-family: "MyriadPro";
  color: #16a796;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 5px;
  font-style: italic;
}

.tbl-mybag-comercial {
  font-family: "MyriadPro";
  font-size: 12px;
  width: 100%;
}

.tbl-mybag-comercial th {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  vertical-align: top;
}

.tbl-mybag-comercial td {
  padding: 5px;
  border-bottom: 1px solid #000000;
}

.tbl-mybag-comercial .td-preview {
  width: 37px;
  max-width: 37px;
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-comercial .td-title {
  width: 350px;
  max-width: 350px;
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-comercial .td-icon,
.tbl-mybag-comercial .td-type,
.tbl-mybag-comercial .td-size {
  vertical-align: top;
  text-align: center;
}

.tbl-mybag-comercial .td-company {
  vertical-align: top;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
  font-size: 13px;
}

.tbl-mybag-comercial .td-link {
  vertical-align: top;
  text-align: right;
  font-style: italic;
  color: #26a9b3;
}

.tbl-mybag-certificates {
  font-family: "MyriadPro";
  /* font-size: 12px; */
  width: 100%;
}

.tbl-mybag-certificates th {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  vertical-align: bottom;
}

.tbl-mybag-certificates td {
  padding: 5px;
  border-bottom: 1px solid #000000;
}

.tbl-mybag-certificates .td-preview {
  width: 63px;
  max-width: 63px;
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-certificates .td-title {
  width: 300px;
  max-width: 300px;
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-certificates .td-authors {
  vertical-align: top;
  text-align: left;
  font-style: italic;
  width: 200px;
  max-width: 200px;
}

.tbl-mybag-certificates .td-date-category {
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-certificates .td-download {
  vertical-align: top;
  text-align: right;
  font-style: italic;
  color: #26a9b3;
}

.tbl-mybag-certificates .td-download a {
  font-style: italic;
  color: #26a9b3;
  text-decoration: none;
}

.tbl-mybag-certificates .th-icon-type {
  vertical-align: top;
  text-align: center;
}

.tbl-mybag-scientific {
  font-family: "MyriadPro";
  font-size: 12px;
  width: 100%;
}

.tbl-mybag-scientific th {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  vertical-align: top;
}

.tbl-mybag-scientific td {
  padding: 5px;
  border-bottom: 1px solid #000000;
}

.tbl-mybag-scientific .eposter-preview-wrapper {
  position: relative;
  float: left;
  width: 62px;
  height: 67px;
}

.tbl-mybag-scientific .eposter-preview-cat-color {
  position: relative;
  float: left;
  width: 9px;
  height: 67px;
}

.tbl-mybag-scientific .eposter-preview-img {
  position: relative;
  float: left;
  width: 53px;
  height: 67px;
  background-color: #000000;
}

.tbl-mybag-scientific .eposter-preview-number-wrapper {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
}

.tbl-mybag-scientific .eposter-preview-container {
  width: 115px;
  height: 67px;
}

.tbl-mybag-scientific .td-title {
  width: 230px;
  max-width: 230px;
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-scientific .td-authors {
  vertical-align: top;
  text-align: left;
  font-style: italic;
  width: 160px;
  max-width: 160px;
}

.tbl-mybag-scientific .td-category {
  vertical-align: top;
  text-align: left;
}

.tbl-mybag-scientific .td-download {
  vertical-align: top;
  text-align: right;
  font-style: italic;
  color: #26a9b3;
}

.tbl-mybag-scientific .video-preview-container {
  width: 115px;
  height: 67px;
}

.tbl-mybag-scientific .video-preview-wrapper {
  position: relative;
  float: left;
  width: 115px;
  height: 67px;
}

.tbl-mybag-scientific .video-preview-cat-color {
  position: relative;
  float: left;
  width: 9px;
  height: 67px;
}

.tbl-mybag-scientific .video-preview-img {
  position: relative;
  float: left;
  width: 106px;
  height: 67px;
  background-color: #000000;
}



.days-sessions-container.rooms3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.days-sessions-container.rooms3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.days-sessions-container.rooms3::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}



.room-sessions-header-name {
  background-color: #1a1a1a;
  height: 15px;
  font-family: "Dotmatrx";
  font-size: 13px;
  color: #f6dd00;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
}




.room-sessions-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.room-sessions-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.room-sessions-area::-webkit-scrollbar-thumb {
  background-color: #f6dd00;
}

.tbl-room-sessions-panel {
  width: 100%;
}

.tbl-room-sessions-panel td {
  vertical-align: top;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
  padding: 5px;
  border-bottom: 1px solid #000000;
}

.tbl-room-sessions-panel .noborder,
.tbl-room-sessions-panel.showcase .noborder {
  border-bottom: 0px solid #000000;
}

.tbl-room-sessions-panel .td-title img {
  max-height: 20px;
  /*max-width: 100px;*/
}

.tbl-room-sessions-panel .td-title a {
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000 !important;
  text-decoration: none;
  text-transform: uppercase;
}

.tbl-room-sessions-panel .td-title.not-theme {
  color: #f2f2f2 !important;
}

.tbl-room-sessions-panel tr:hover {
  background-color: #f6dd00;
  cursor: pointer;
}

.open-room-icon-container {
  position: absolute;
  bottom: 3px;
  right: 6px;
  text-align: right;
}

.slide-bar.rooms3 {
  background-color: #f6dd00;
  height: 15px;
  position: relative;
  float: left;
  width: 940px;
  margin-left: 10px;
  margin-right: 10px;
}

.slide-bar-btn.rooms3 {
  position: relative;
  float: left;
  text-align: center;
  width: 940px;
  height: 61px;
  margin-left: 10px;
  margin-right: 10px;
}

.slide-bar-btn-container {
  width: 200px;
  height: 51px;
  background-image: url(/img/slide-bar-btn.png);
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
}

.slide-bar-btn-container img {
  max-height: 20px;
}

.select-theme-container {
  position: relative;
  float: left;
  width: 300px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.select-theme-container select {
  width: 300px;
  height: 35px;
  background-color: #f6dd00;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  font-style: italic;
  border: 0px;
}

.select-theme-container select:focus {
  outline: none;
}

.select-days-container {
  position: relative;
  float: left;
  width: 620px;
  height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  /*border:2px solid #f6dd00;*/
}

.select-days-wrapper {
  width: 616px;
  height: 31px;
  border: 2px solid #f6dd00;
}

.select-days-wrapper .btn-day {
  height: 31px;
  background-color: #000000;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #f6dd00;
  text-align: center;
  position: relative;
  float: left;
  line-height: 1.8;
  cursor: pointer;
}

.select-days-wrapper .btn-day.selected {
  background-color: #f6dd00;
  color: #000000;
}

.days-sessions-container.rooms4 {
  position: absolute;
  top: 160px;
  width: 1280px;
  z-index: 250;
  left: 0;
  right: 0;
  margin: auto;
}

.slide-bar.rooms4 {
  background-color: #f6dd00;
  height: 15px;
  position: relative;
  float: left;
  width: 1260px;
  margin-left: 10px;
  margin-right: 10px;
}

.slide-bar-btn.rooms4 {
  position: relative;
  float: left;
  text-align: center;
  width: 1260px;
  height: 61px;
  margin-left: 10px;
  margin-right: 10px;
}

.select-days-container.rooms4 {
  width: 620px;
  height: 40px;
  margin: 0 auto;
  position: relative;
  float: none;
  /*border:2px solid #f6dd00;*/
}

.room-sessions-container {
  position: relative;

  max-height: 660px;
  /*width: 968px;*/
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f6dd00 #F5F5F5;
}

.room-sessions-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.room-sessions-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.room-sessions-container::-webkit-scrollbar-thumb {
  background-color: #f6dd00;
}

.backdrop-days-sessions-detail {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
}

.days-sessions-detail-container {
  position: absolute;
  top: 160px;
  width: 1220px;
  z-index: 250;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 240px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.days-sessions-detail-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.days-sessions-detail-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.days-sessions-detail-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.room-sessions-wrapper.showcase {
  width: 430px;
}

.room-sessions-wrapper.nomargin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tbl-room-sessions-panel.showcase td {
  vertical-align: top;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #000000;
  text-transform: uppercase;
  padding: 10px;
  border-bottom: 1px solid #000000;
}

.room-sessions-area.showcase {
  /*height:400px;*/
  background-color: #ffffff;
  border: 10px solid #333333;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 460px;
  max-height: 460px;
  overflow-y: auto;
}

.room-sessions-wrapper.previous-room,
.room-sessions-wrapper.next-room {
  margin-top: 70px;
}

.room-sessions-detail-arrow-container {
  position: relative;
  float: left;
  width: 80px;
  height: 346px;
  margin-top: 70px;
}

.room-sessions-detail-arrow-container.left-arrow {
  text-align: left;
}

.room-sessions-detail-arrow-container.right-arrow {
  text-align: right;
}

.room-sessions-detail-arrow-container img {
  max-height: 80px;
  margin-top: 120px;
  cursor: pointer;
}

.slide-bar-opened-room {
  background-color: #f6dd00;
  height: 10px;
  position: relative;
  float: left;
  width: 100%;
}

.slide-bar-btn-opened-room {
  position: relative;
  float: left;
  text-align: center;
  width: 100%;
  height: 61px;
}

.slide-bar-btn-opened-room-container {
  width: 67px;
  height: 27px;
  background-image: url(/img/opened-room-sessions-bottom-arrow.png);
  margin-top: -10px;
  margin-left: auto;
  margin-right: auto;
}

.participant-wrapper {
  position: relative;
  float: left;
  /*width:150px;*/
}

.participant-pic {
  position: relative;
  float: left;
}

.participant-pic img {
  border-radius: 50%;
  max-height: 40px !important;
  margin-top: 5px;
}

.participant-name {
  position: relative;
  float: left;

  font-family: "MyriadPro";
  font-size: 11px;
  color: #000000;
  margin-left: 5px;
  margin-top: 10px;
}

.person-badge-container {
  position: absolute;
  z-index: 300;
  width: 300px;
  /*height:650px;
  background-color:#ff1d25;*/
  /*top:140px;*/
  left: 0px;
  right: 0;
  margin: auto;
}

.person-badge-container.left {
  left: 20px;
  right: initial;
}

.btn-close-badge-container {
  position: absolute;
  right: -20px;
  top: 118px;
  cursor: pointer;
}

.person-badge-area {
  width: 260px;
  height: 360px;
  /* background-image: url(/img/person-badge-background.png); */
  margin-top: 118px;
  text-align: center;
  font-family: "MyriadPro";
  /* color: #ffffff; */
  color: #000000;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.person-badge-area .badge-congress-title {
  font-size: 20px;
}

.person-badge-area .badge-person-name {
  font-size: 30px;

}

.person-badge-area .badge-person-name.vpracticvm,
.person-badge-area .badge-person-name.VPRACTICVM {
  font-size: 30px;
  font-weight: bold;
  background-color: #f2d3b1;
}

.badge-person-name.\31 REUNIONVIRTUALAREAS2021,
.badge-person-name.\31 reunionvirtualareas2021 {
  color: #ffffff;
}

.badge-congress-title.\31 REUNIONVIRTUALAREAS2021,
.badge-congress-title.\31 reunionvirtualareas2021 {
  color: #ffffff;
}



.badge-person-name.\32 REUNIONVIRTUALAREAS2021,
.badge-person-name.\32 reunionvirtualareas2021 {
  color: #ffffff;
}

.person-badge-area .badge-person-name.cnp2023,
.person-badge-area .badge-person-name.CNP2023 {
  text-transform: uppercase;
  font-size: 25px;
}

.person-badge-area .badge-person-name.cnp2024,
.person-badge-area .badge-person-name.CNP2024 {
  text-transform: uppercase;
  font-size: 25px;
}



.badge-congress-title.\32 REUNIONVIRTUALAREAS2021,
.badge-congress-title.\32 reunionvirtualareas2021 {
  color: #ffffff;
}


.badge-congress-title.\33 reunionvirtualareas2022 {
  visibility: hidden;
}

.sponsorlogosrotator.\33 reunionvirtualareas2022 {
  margin-right: 25px;
}


.badge-congress-title.\34 reunionvirtualareas2022 {
  visibility: hidden;
}

.person-badge-area .badge-person-desc {
  font-size: 14px;
}

.person-badge-area .badge-bottom-btns {
  width: 70px;
  height: 70px;
  background-color: #1f625d;
  position: relative;
  float: left;
  border-radius: 10%;
  margin-top: 15px;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 12px;
}

.person-badge-area .badge-bottom-btns img {
  margin-top: 10px;
  max-height: 25px;
}

.person-badge-area .badge-bottom-btns.margin-btns {
  margin-left: 25px;
  margin-right: 25px;
}

.person-badge-bottom.sefc2022 {
  display: none;
}

.person-badge-bottom {
  width: 300px;
  height: 60px;
  font-family: "MyriadPro";
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #000000;
  padding-top: 20px;
  background-image: url(/img/person-badge-bottom-background.png);
}

.person-badge-bottom.vip {
  color: #ffffff;
  background-image: url(/img/person-badge-bottom-background-vip.png);
}

.person-badge-company-logo {
  width: 90px;
  height: 110px;
  background-color: #FFF;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  position: absolute;
  text-align: center;
  /*padding-top: 10px;*/
  padding-bottom: 10px;
}

.person-badge-company-logo img {
  max-height: 130px;
  margin-top: 0px;
}

.person-badge-company-logo-main img {
  max-width: 120px;
  max-height: 120px;
}

.img-badge-avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  padding: 3px;
  border: 2px solid #ffffff;
}

.coauthors-opened-detail {
  font-style: italic;
  font-size: 12px;
}

/*

.top-bar-video-container{
 width:70%;

 margin-left:60px;
 position:relative;
 float:left;
}

.top-bar-container{
 width:100%;
 height:40px;
 background-color:#0b0b0c;    
 min-width:697px;

}




.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
  max-height:700px;
  min-width:697px;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height:700px;
}

.video-rooms-bottom-container{
  width:100%;
  height:200px;
  background-color:#f6dd00;
  min-width:697px;
}
    */

.top-bar-econgress-room {
  height: 40px;
  background-color: #0a0a0c;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.top-exit-btn-container {
  width: 150px;
  height: 40px;
  background-color: #0a0a0c;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}

.sponsored-true .right-panel-logo-company-container-fixed {
  margin-top: 40px;
  background-color: #ffffff;
}

.sponsored-true .right-panel-econgress-room-container {
  position: relative;
  width: 380px;
  height: calc(100% - 180px);
  background-color: #ffffff;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f6dd00 #F5F5F5;
}

.sponsored-false .right-panel-logo-company-container-fixed {
  display: none;
}

.sponsored-false .right-panel-econgress-room-container {
  position: relative;
  width: 380px;
  height: calc(100% - 80px);
  background-color: #ffffff;
  overflow-y: auto;
  margin-top: 40px;
  scrollbar-width: thin;
  scrollbar-color: #f6dd00 #F5F5F5;
}




.right-panel-econgress-room-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.right-panel-econgress-room-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.right-panel-econgress-room-container::-webkit-scrollbar-thumb {
  background-color: #f6dd00;
}

.video-stream-container {
  float: left;
  width: 100%;
  /*height:700px;
  height:100%;  */
  margin-top: 40px;
  height: calc(100vh - 120px);
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  /*height:700px;*/
  height: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bottom-rooms-videos-navigation-container {
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 40px;
  /*height:190px;*/
  height: 230px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
}

.btn-bars-econgress-room-container {
  position: relative;
  float: left;
  width: 40px;
  height: 40px;
  background-color: #000000;
}

.btn-bars-econgress-room-container img {
  max-width: 25px;
  margin-top: 10px;
  margin-left: 5px;
}

.top-bar-btns-container {
  position: relative;
  float: left;
  height: 40px;
  margin-left: 60px;
}

.top-bar-btn-wrapper {
  position: relative;
  float: left;
  margin-top: 8px;
  width: 120px;
  /*height:25px;*/
  background-color: #f6dd00;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  padding: 3px;
  margin-right: 20px;
}

.top-bar-title-wrapper {
  position: relative;
  float: left;
  margin-top: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #f6dd00;
  padding: 3px;
  margin-right: 20px;
  overflow: auto;
}

.top-bar-rating-wrapper {
  position: absolute;
  top: 7px;
  right: 150px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #f6dd00;
  z-index: 999;
  background-color: #000000;
}



.exit-btn-wrapper {
  width: 110px;
  height: 40px;
  cursor: pointer;
}

.exit-btn-text {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  font-size: 20px;
  color: #f6dd00;
  text-transform: uppercase;
  text-align: right;
  font-weight: bold;
  margin-top: 8px;
  margin-right: 8px;
}

.exit-btn-img {
  position: relative;
  float: right;
  margin-top: 8px;
}

.bottom-rooms-videos-navigation-days-nav {
  text-align: center;
}

.bottom-rooms-videos-navigation-days-nav .btn-days-nav {
  /* width: 160px; */
  padding: 5px;
  background-color: transparent;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #f6dd00;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.bottom-rooms-videos-navigation-days-nav .btn-days-nav.selected {
  background-color: #f6dd00;
  color: #000000;
  cursor: default;
}

.rooms-navigation-container {
  /*height:150px;*/
  height: 190px;
  margin-top: 5px;
}

.rooms-navigation-arrows-container {
  width: 50px;
  /*height:150px;*/
  height: 190px;
}

.rooms-navigation-arrows-container.left-arrows {
  position: relative;
  float: left;
}

.rooms-navigation-arrows-container.right-arrows {
  position: relative;
  float: right;
}

.arrow-room-nav-container {
  margin-top: 5px;
  text-align: center;
}

.arrow-room-nav-container img {
  max-height: 25px;
  cursor: pointer;
}

.arrow-streams-nav-container {
  margin-top: 55px;
  text-align: center;
}

.arrow-streams-nav-container img {
  cursor: pointer;
}

.nav-rooms-streams-container {
  /*height:150px;*/
  height: 190px;
  margin-left: 50px;
  margin-right: 50px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.nav-rooms-entry-wrapper {
  display: inline-block;
  width: 264px;
  background-color: transparent;
  font-family: "Dotmatrx";
  font-size: 13px;
  color: #f6dd00;
  text-align: center;
  text-transform: uppercase;
  padding: 8px;
  cursor: pointer;
}

.nav-rooms-entry-wrapper.selected {
  background-color: #f6dd00;
  color: #000000;
  cursor: default;
}

.nav-rooms-separator-wrapper {
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  width: 1px;
  height: 25px;
  background-color: #f6dd00;
  vertical-align: bottom;
  white-space: normal;
}

.nav-rooms-separator-wrapper.nocolor {
  background-color: transparent;
}

.nav-rooms-streams-content {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  text-align: center;
}

.nav-rooms-streams-separator {
  height: 5px;
  background-color: #f6dd00;
  /*margin-top:30px;*/
}

.stream-preview-room-schedule-container {
  display: inline-block;
  width: 280px;
  height: 155px;
  background-color: #fff;
  margin-right: 9px;
  position: relative;
  vertical-align: bottom;
  white-space: normal;
  cursor: pointer;
  background-size: 280px;
  background-repeat: no-repeat;
}

.stream-preview-room-schedule-container.selected {
  border: #f6dd00 solid 2px
}

.stream-preview-room-time-container {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #f6dd00;
  color: #000000;
  font-family: "MyriadPro";
  font-size: 12px;
  padding: 3px;
  text-align: center;
  font-weight: bold;
  z-index: 200;
}

.stream-preview-room-now-container {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #ff1d25;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 12px;
  padding: 3px;
  text-align: center;
  font-weight: bold;
}

.stream-preview-room-title-container {
  position: absolute;
  bottom: 0px;
  width: 270px;
  background-color: #1a1a1a;
  font-family: MyriadPro;
  font-size: 13px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px;
  text-align: left;
}

.nav-rooms-streams-content::-webkit-scrollbar {
  display: none;
}

.stream-preview-more-container {
  text-align: right;
}

.stream-preview-room-backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 100;
}

.logo-company-container-econgress-room {
  text-align: center;
  padding: 10px;
}

.logo-company-container-econgress-room.\31 reunionvirtualareas2021,
.logo-company-container-econgress-room.\31 REUNIONVIRTUALAREAS2021 {
  background-color: #15144f;
}

.logo-company-container-econgress-room.\32 reunionvirtualareas2021,
.logo-company-container-econgress-room.\32 REUNIONVIRTUALAREAS2021 {
  background-color: #15144f;
}

.logo-company-container-econgress-room.semnim2021 {
  background-color: #266eb6;
}

.logo-company-container-econgress-room.isin2021 {
  background-color: #35476f;
}

.logo-company-container-econgress-room img {
  max-width: 360px;
  max-height: 80px;
}

.econgress-room-accordion-header {
  background-color: #f2f2f2;
  border-bottom: 1px solid #bebebe;
  font-family: "MyriadPro";
  font-size: 16px;
  color: #333333;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2%;
  padding-top: 2%;
  font-weight: bold;
}

.econgress-room-accordion-header-icon-container {
  position: relative;
  float: left;
  margin-right: 5px;
}

.econgress-room-chat-area-container {
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.econgress-room-chat-area-container.block-1 {
  height: 82%;
}

.econgress-room-chat-area-container.block-2 {
  height: 42%;
}

.econgress-room-chat-area-container.block-3 {
  height: 23%;
}

.econgress-room-chat-area-container.block-4 {
  height: 16.5%;
}

.econgress-room-chat-content-container {
  width: auto;
  height: calc(100% - 45px);
  background-color: #ffffff;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-chat-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.econgress-room-chat-content-container::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.econgress-room-chat-content-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}


.econgress-room-chat-text-input-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: auto;
  height: auto;
  background-color: #ffffff;
  border-top: 1px solid #f2f2f2;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 50;
}

.econgress-room-chat-avatar {
  position: relative;
  float: left;
}

.econgress-room-chat-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}





.econgress-room-chat-input-container input:focus {
  outline: none;
}

.econgress-room-chat-separator {
  height: 10px;
}

.econgress-room-chat-text {
  position: relative;
  float: left;
  padding: 5px;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  margin-left: 20px;
}

.peding-approval {
  border: 1px solid red;
}

.econgress-room-chat-text-person-name {
  font-size: 12px;
}

.econgress-room-questions-area-container {
  /*height:150px;*/
  max-height: 150px;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-questions-area-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-questions-area-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-questions-area-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-questions-area-container label {
  font-size: 10px;
}

.econgress-room-questions-wrapper {
  margin-top: 10px;
}

.econgress-room-question-icon {
  position: relative;
  float: left;
}

.econgress-room-question-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #4d4d4d;
  max-width: 340px;
  margin-left: 10px;
}

.econgress-room-question-text-question {
  font-weight: bold;
}

.econgress-room-question-icon-answer {
  position: relative;
  float: right;
}

.econgress-room-vote-area-container {
  /*height:150px;*/
  /* max-height: 150px; */
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-vote-area-container.block-1 {
  height: 82%;
}

.econgress-room-vote-area-container.block-2 {
  height: 42%;
}

.econgress-room-vote-area-container.block-3 {
  height: 23%;
}

.econgress-room-vote-area-container.block-4 {
  height: 16.5%;
}

.econgress-room-vote-area-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-vote-area-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-vote-area-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-vote-question-container {
  margin-bottom: 30px;
}

.econgress-room-right-panel-btn {
  position: relative;
  /* float: right; */
  padding: 5px;
  /* margin: 5px; */
  background-color: #00a99d;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
}

.econgress-room-survey-area-container {
  /*height:150px;*/
  max-height: 150px;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-survey-area-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-survey-area-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-survey-area-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-overview-container {
  position: absolute;
  z-index: 400;
  max-width: 700px;
  max-height: 80%;
  top: 40px;
  left: 100px;
  background-color: #ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-overview-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-overview-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-overview-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-overview-container .session-type {
  color: #22b4aa;
  text-transform: uppercase;
}

.econgress-room-overview-container .presentation-title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
}

.econgress-room-overview-container .presentation-authors {
  font-style: italic;
}

.econgress-room-overview-container .presentation-overview {
  font-size: 16px;
  line-height: 1.6;
}

.econgress-room-overview-container .moderator-wrapper {
  position: relative;
  float: right;
}

.econgress-room-overview-container .moderatored-by {
  position: relative;
  float: left;
  font-weight: bold;
  margin-right: 5px;
}

.econgress-room-overview-container .moderator-avatar {
  position: relative;
  float: left;
  margin-right: 10px;
}

.econgress-room-overview-container .moderator-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.econgress-room-overview-container .moderator-name {
  position: relative;
  float: left;
  margin-top: 25px;
}

.econgress-room-overview-container .sponsored-by {
  text-align: right;
  font-size: 11px;
  font-weight: bold;
}

.econgress-room-agenda-container {
  position: absolute;
  z-index: 400;
  max-height: 80%;
  top: 40px;
  /* width: 380px;
  left: 245px; */
  width: 500px;
  left: 100px;
  background-color: #ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 16px;
  color: #4d4d4d;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-agenda-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-agenda-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-agenda-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-agenda-container .session-time {
  position: relative;
  float: left;
  width: 100px;
  color: #000000;
}

.econgress-room-agenda-container .session-title {
  position: relative;
  float: left;
  width: 280px;
  color: #000000;
}

.econgress-room-agenda-separator {
  width: 100%;
  height: 10px;
}

.econgress-room-agenda-container .presentation-time {
  position: relative;
  float: left;
  width: 100px;
  color: #cccccc;
}

.presentation-time.LAHRS2021,
.presentation-time.lahrs2021 {

  visibility: hidden;
}

.econgress-room-agenda-container .presentation-wrapper {
  position: relative;
  float: left;
  width: 300px;
  color: #000000;
}

.econgress-room-agenda-container .presenter-avatar {
  position: relative;
  float: left;
}

.econgress-room-agenda-container .presenter-avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.econgress-room-agenda-container .presenter-name {
  position: relative;
  float: left;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 15px;
}

.econgress-room-agenda-container .presentation-title {
  text-transform: uppercase;
  font-size: 14px;
}



.left-column-cont-total {
  margin-right: 80px;
  /* The size of the fixed width column + space between them 
        + sum of padding used in both columns. */
}

.clear {
  clear: both;
}

.bottom-open-close-videos-slider-container {
  position: absolute;
  width: 100%;
  height: 40px;
  text-align: center;
  bottom: 0;
}

.bottom-open-close-videos-slider-container.VIIcursoTDAH2022,
.bottom-open-close-videos-slider-container.viicursotdah2022,
.bottom-open-close-videos-slider-container.VIICURSOTDAH2022 {
  display: none;
}

.bottom-open-close-videos-slider-container.VITDAH,
.bottom-open-close-videos-slider-container.vitdah {
  display: none;
}

.bottom-open-close-videos-slider-container.UROVI2021,
.bottom-open-close-videos-slider-container.urovi2021 {
  display: none;
}

.bottom-open-close-videos-slider-container.ENCUENTROSEXPERTO2021,
.bottom-open-close-videos-slider-container.encuentrosexperto2021,
.bottom-open-close-videos-slider-container.TROMBOENCUENTROS2021,
.bottom-open-close-videos-slider-container.tromboencuentros2021 {
  display: none;
}

.bottom-open-close-videos-slider-wrapper {
  width: 98px;
  height: 30px;
  background-image: url(/img/econgress-room-open-videos-slider.png);
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  cursor: pointer;
}

.bottom-open-close-videos-slider-wrapper.close {
  background-image: url(/img/econgress-room-close-videos-slider.png);
}

.bottom-open-close-videos-slider-text {
  font-family: "MyriadPro";
  font-size: 11px;
  color: #000000;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: bold;
}

.econgress-room-files-container {
  position: absolute;
  z-index: 400;
  width: 900px;
  max-height: 80%;
  top: 40px;
  left: 392px;
  background-color: #ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;

  font-family: "MyriadPro";
  font-size: 16px;
  color: #4d4d4d;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.econgress-room-files-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.econgress-room-files-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.econgress-room-files-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.econgress-room-files-header-container {
  font-family: "MyriadPro";
  font-size: 22px;
  color: #000000;

  font-weight: bold;
  border-bottom: 1px solid #d7d7d7;
  padding: 25px;
}

.econgress-room-files-area-container {
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
}

.tbl-econgress-room-files {
  width: 100%;
  color: #000000;
}

.tbl-econgress-room-files td {
  padding: 5px;
  vertical-align: top;
}

.tbl-econgress-room-files .session-time {
  font-weight: bold;
  font-size: 16px;
}

.tbl-econgress-room-files .session-title {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  width: 600px;
}

.tbl-econgress-room-files .presentation-title {
  text-align: left;
  font-size: 14px;
  width: 600px;
}

.tbl-econgress-room-files .presentation-filetype,
.tbl-econgress-room-files .presentation-size {
  text-align: right;
  font-size: 14px;
  font-style: italic;
}

.tbl-econgress-room-files .presentation-download {
  text-align: right;
  font-size: 14px;
  color: #00a99d;
  text-decoration: underline;
}

.econgress-room-files-footer-container {
  font-family: "MyriadPro";
  font-weight: bold;
  border-top: 1px solid #d7d7d7;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  height: 30px;
}

.video-full-width .right-column {
  display: none;
}

.video-full-width .video-stream-container {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  width: auto;
  height: auto;
}

.econgress-room-btn-side-panel-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 92px;
  margin: auto;
  background-image: url(/img/econgress-room-open-side-panel.png);
  cursor: pointer;
}

.econgress-room-btn-side-panel-container.close {
  background-image: url(/img/econgress-room-close-side-panel.png);
}


.login-header-container {
  height: 50px;
  background-image: url(/img/login-header.png);
}

.close-login-container {
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.login-area-container {
  height: 500px;
  background-color: #333333;
  padding-left: 20px;
  padding-right: 20px;
}

.login-area-container.viicursotdah2022 {
  height: 520px;
}

.login-area-container.cnp2023 {
  height: 650px;
}




.login-title {
  font-family: "MyriadPro";
  font-weight: bold;
  color: #ffffff;
  font-size: 26px;

}

.avatar-login-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  /* display:none; */
}

.form-field-wrapper {
  border-bottom: 1px solid #e6e6e6;

  height: 25px;
}

.form-field-icon-container {
  position: relative;
  float: left;
  height: 20px;
}

.form-field-input-container {
  position: relative;
  float: left;
  height: 20px;
  margin-left: 10px;
  margin-top: 3px;
}

.form-field-input-container input {
  width: 270px;
  height: 15px;
  border: 0px;
  background-color: #333333;
  color: #e6e6e6;
}



.form-field-input-container input:-webkit-autofill,
.form-field-input-container input:-webkit-autofill:hover,
.form-field-input-container input:-webkit-autofill:focus {
  border: 0px;
  -webkit-text-fill-color: #e6e6e6;
  -webkit-box-shadow: 0 0 0px 1000px #333333 inset;
  box-shadow: 0 0 0px 1000px #333333 inset;
  transition: background-color 5000s ease-in-out 0s;
}




.form-field-input-container input:focus {
  outline: none;
}

.form-error-msg {
  font-family: "MyriadPro";
  color: #ff0000;
  font-size: 14px;
  text-align: right;
  margin-top: 5px;
}

.form-fields-login-separator {
  height: 30px;
}

.login-form-button {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00a99d;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.login-form-recover-password-container {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}

.login-form-recover-password-container a {
  color: #00a99d;
  text-decoration: none;
}

.login-form-registration-container {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 18px;
  text-align: center;
}

.login-form-registration-container a {
  color: #ffffff;
  text-decoration: none;
}

.login-my-profile-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  margin: auto;
  width: 500px;
  height: calc(100% - 180px);
  background-color: #f2f2f2;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.login-my-profile-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.login-my-profile-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.login-my-profile-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.login-my-profile-wrapper-split {
  position: relative;
  float: left;
  width: 235px;
}

.login-my-profile-title {
  font-family: "MyriadPro";
  font-size: 32px;
  font-weight: bold;
  color: #333333;
}

.login-my-profile-sponsored-by {
  font-family: "MyriadPro";
  font-size: 12px;
  color: #333333;
}

.login-my-profile-separator {
  height: 20px;
}

.login-my-profile-spacer {
  width: 20px;
  position: relative;
  float: left;
  min-height: 20px;
}

.avatar-profile-add img {
  max-width: 100px;
  cursor: pointer;
}

.login-company-logo {
  font-family: "MyriadPro";
  font-size: 16px;
}

.login-company-logo img {
  max-width: 235px;
}

.login-my-profile-subtitle {
  font-family: "MyriadPro";
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.login-my-profile-form-field {
  border-bottom: 1px solid #929292;
  padding-bottom: 3px;
  margin-top: 3px;
}

.login-my-profile-form-field input {
  background-color: #f2f2f2;
  width: 100%;
  border: 0px;
  font-size: 18px;
  color: #333333;
  padding: 0px;
}

.login-my-profile-form-field input:focus {
  outline: none;
}

.login-my-profile-form-label {
  font-family: "MyriadPro";
  font-size: 12px;
  text-transform: uppercase;
  color: #333333;
}

.login-my-profile-roll {
  width: 235px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-image: url(/img/bck-my-profile-login-role.png);
  font-family: "MyriadPro";
  font-size: 24px;
  text-transform: uppercase;
  color: #333333;
  text-align: center;
  background-repeat: no-repeat;
  font-weight: bold;
}

.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.fields-interest-input {
  width: 164.6px;
  position: relative;
  float: left;
}

.fields-interest-input label {
  cursor: pointer;
  font-family: "MyriadPro";
  font-size: 14px;
}

.row-fields-interest {
  margin-top: 15px;
}

.networking-desc-my-profile-login-container {
  font-family: "MyriadPro";
  font-size: 13px;
  color: #999999;
  width: 130px;
  margin-left: 20px;
}

.login-my-profile-rgpd-check {
  position: relative;
  float: left;
  width: 20px;
}

.login-my-profile-rgpd-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #333333;
  width: 450px;
  margin-left: 20px;
}

.login-my-profile-rgpd-text a {
  color: #26a9b3;
  text-decoration: none;
}

.login-my-profile-send-btn {
  position: relative;
  float: right;
  padding: 5px;
  background-color: #00a99d;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
}

.person-badge-barcode-container {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "MyriadPro";
  font-size: 11px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.person-badge-barcode-container img {
  max-width: 100px;
}

.person-badge-sponsor-main-container {
  background-color: #ffffff;
  font-family: "MyriadPro";
  font-size: 11px;
  color: #333333;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.person-badge-sponsor-main-container img {
  max-width: 150px;
  max-width: 150px;
}

.person-badge-ribbon {
  width: 90px;
  height: 110px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  position: absolute;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.login-recover-password-instructions {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 14px;
}

.login-recover-password-ok-instructions {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 28px;
}

.form-login {
  margin-top: 20px;
}

.form-login-reset-psw-ok-link a {
  font-family: "MyriadPro";
  color: #00a99d;
  font-size: 28px;
  text-decoration: none;
}

.add-profile-avatar-container {
  position: absolute;
  top: 0;
  left: 0;
  /*width:200px;
  height:200px;*/
  background-color: #f5f6f7;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  z-index: 300;
  padding: 10px;
  width: 280px !important;
}

.add-profile-avatar-opt-wrapper {
  position: relative;
  float: left;
  text-align: center;
  padding: 5px;
}

.add-profile-avatar-opt-wrapper img {
  max-height: 35px;
  cursor: pointer;
}

.add-profile-avatar-opt-text {
  font-family: "MyriadPro";
  color: #343433;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}

.add-profile-avatar-opt-wrapper-spacer {
  position: relative;
  float: left;
  width: 40px;
  height: 50px;
}

.add-profile-avatar-close-container {
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
}

.networking-main-area-container {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1210px;
}

.networking-content-wrapper {
  position: absolute;
  top: 250px;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 320px);
  width: 1210px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.networking-content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.networking-content-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.networking-content-wrapper::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.networking-top-btn-container {
  position: relative;
  float: left;
  width: 300px;
  background-color: #4d4d4d;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 6px;
  padding-bottom: 4px;
  text-align: center;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  min-height: 52px;
}

.networking-top-btn-container.selected {
  background-color: #00a99d;
}

.networking-top-btn-container.social-video {
  margin-left: 103px;
}

.networking-top-btn-container.social-video .networking-top-btn-wrapper {
  margin-top: 15px;
}

.networking-top-btn-icon-delegates {
  position: relative;
  float: left;
  width: 36px;
  margin-top: 5px;
}

.networking-top-btn-text-delegates {
  position: relative;
  float: left;
  text-align: center;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 40px;

  font-weight: bold;
}

.networking-top-btn-wrapper {
  display: inline-block;
}

.networking-top-btn-icon-social {
  position: relative;
  float: left;
}

.networking-top-btn-icon-social-spacer {
  position: relative;
  float: left;
  width: 15px;
  height: 10px;
}

.networking-top-btn-text-social-video {
  position: relative;
  float: left;
  text-align: center;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 15px;

  font-weight: bold;
}

.networking-left-side-panel-container {
  position: relative;
  float: left;
  width: 800px;
  height: 550px;
  background-color: #f2f2f2;
}

.persons-list-full-width .networking-left-side-panel-container {
  width: 100%;
}

.networking-right-side-panel-container {
  position: relative;
  float: left;
  width: 395px;
  height: 550px;
  background-color: #f2f2f2;
  border-left: 5px solid #ffffff;
}

.persons-list-full-width .networking-right-side-panel-container {
  display: none;
}

.in-room-container {
  position: relative;
  float: left;
  margin-top: 20px;
  margin-left: 10px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.in-room-container img {
  max-height: 15px;
}

.in-room-number {
  color: #00a99d;
  font-size: 16px;
  font-weight: normal;
}

.switch-med-visitors-container {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  margin-top: 25px;
  margin-left: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6e6e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: #00a99d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #e6e6e6;
}

input:focus+.slider {
  box-shadow: 0 0 1px #e6e6e6;
}

input:checked+.slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.in-room-search-container {
  position: relative;
  float: left;
  margin-left: 15px;
  margin-top: 15px;
}

.in-room-search-input {
  height: 25px;
  width: 150px !important;
  border: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: 10px;
}

.networking-delegates-company-logo-container {
  position: relative;
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.networking-delegates-company-logo-container img {
  max-height: 40px;
}

.left-side-options-in-room-persons {
  position: relative;
  float: left;
  width: 60px;
  height: calc(100% - 55px);
  margin-left: 2px;
  margin-right: 15px;
}

.left-side-opt-in-room-persons {
  text-align: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
}

.left-side-opt-in-room-persons.selected {
  background-color: #00a99d;
}

.left-side-opt-in-room-persons.delegates {
  background-image: url(/img/networking-opt-in-room-persons-delegates.png);
  background-position: center;
  background-repeat: no-repeat;
}

.left-side-opt-in-room-persons.delegates.selected {
  background-image: url(/img/networking-opt-in-room-persons-delegates-selected.png);
  background-position: center;
  background-repeat: no-repeat;
}

.left-side-opt-in-room-persons.settings {
  background-image: url(/img/networking-opt-in-room-persons-settings.png);
  background-position: center;
  background-repeat: no-repeat;
}

.left-side-opt-in-room-persons.settings.selected {
  background-image: url(/img/networking-opt-in-room-persons-settings-selected.png);
  background-position: center;
  background-repeat: no-repeat;
}

.in-room-persons-list-container {
  position: relative;
  float: left;
  height: calc(100% - 55px);
  width: calc(100% - 80px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.in-room-persons-list-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.in-room-persons-list-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.in-room-persons-list-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.networking-person-entry-container {
  position: relative;
  float: left;
  width: 145px;
  height: 160px;
  background-color: #ffffff;
  margin-right: 13px;
  margin-bottom: 15px;
  -moz-box-shadow-bottom: 4px 4px 4px #a2a2a2;
  -webkit-box-shadow-bottom: 4px 4px 4px #a2a2a2;
  box-shadow: 4px 4px 4px #a2a2a2;
  text-align: center;
  padding: 10px;
}

.persons-list-full-width .networking-person-entry-container {
  margin-right: 22px;
}

.networking-person-entry-container.visitador-medico {
  background-image: url(/img/networking-person-entry-visitador-medico-background.png);
}

.networking-person-entry-container.vip {
  background-image: url(/img/networking-person-entry-vip-background.png);
}

.networking-person-entry-photo {
  text-align: center;
  margin-top: 5px;
}

.networking-person-entry-photo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.networking-person-entry-container.visitador-medico .networking-person-entry-photo img {
  border: 6px solid #008457;
}

.networking-person-entry-container .networking-person-entry-photo img {
  border: 6px solid;
  border-color: transparent;
}

.networking-person-name-institution-container {
  text-align: center;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 12px;
  margin-top: 5px;
}

.networking-person-connection {
  position: absolute;
  z-index: 200;
  right: 0;
  top: 0;
  width: 52px;
  height: 50px;
  background-image: url(/img/networking-person-list-not-connected.png);
}

.networking-person-connection.connected {
  background-image: url(/img/networking-person-list-connected.png);
}

.person-detail-magnifier-container {
  height: 39px;
  text-align: center;
  padding-top: 15px;
}

.person-detail-magnifier-container img {
  max-height: 25px;
}

.person-detail-container {
  padding: 20px;
  -moz-box-shadow-bottom: 4px 4px 4px #a2a2a2;
  -webkit-box-shadow-bottom: 4px 4px 4px #a2a2a2;
  box-shadow: 4px 4px 4px #a2a2a2;
  height: 400px;
  background-color: #ffffff;
  margin-left: 30px;
  margin-right: 30px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 12px;
}

.person-detail-photo-container {
  text-align: center;
  margin-top: 20px;
}

.person-detail-photo-container img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.person-detail-name-institution {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.person-detail-bottom-chat-contact {
  position: absolute;
  height: 25px;
  z-index: 200;
  bottom: 55px;
  left: 30px;
  right: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.person-detail-bottom-chat-send-message-container {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 12px;
  cursor: pointer;
}

.person-detail-bottom-chat-opt-right {
  position: relative;
  float: right;
  cursor: pointer;
}

.person-detail-bottom-chat-opt-right-separator {
  position: relative;
  float: right;
  height: 15px;
  width: 1px;
  background-color: #535353;
  margin-right: 10px;
  margin-left: 10px;
}

.networking-social-media-video-container {
  width: 1200px;
  height: 550px;
  background-image: url(/img/networking-social-media-video-background.png);
  position: relative;
  float: left;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.networking-social-media-video-frame-area-container {
  background-color: #e6e6e6;
  height: calc(100% - 85px);
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 35px;
  border-radius: 10px;
  border: 8px solid #000000;
}

.networking-social-media-entry {
  position: relative;
  float: left;
  width: 366px;
  height: 100%;
  white-space: nowrap;
  text-align: center;
}

.networking-social-media-entry.instagram {
  max-height: 465px;
  overflow-y: auto;
}

.networking-social-media-entry.facebook {
  max-height: 465px;
  overflow-y: auto;
}

.networking-social-media-separator {
  position: relative;
  float: left;
  width: 3px;
  height: 100%;
  background-color: #000000;
}

.networking-social-media-entry-position {
  margin-top: 150px;
}

.networking-social-media-entry-position img {
  max-height: 150px;
}

.networking-company-logo-container {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  width: 300px;
  text-align: center;
}

.networking-company-logo-container img {
  max-height: 30px;
  margin-top: 2px;
}

.networking-video-image-entry {
  position: relative;
  float: left;
  width: 140px;
  height: 100%;
  white-space: nowrap;
  text-align: center;
}

.networking-video-video-entry {
  position: relative;
  float: left;
  width: 824px;
  height: 100%;
  white-space: nowrap;
  text-align: center;
}

.networking-video-backdrop {
  position: absolute;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.8);
  height: calc(100% - 85px);
  left: 40px;
  right: 40px;
  top: 35px;
  border-radius: 10px;
  border: 8px solid #000000;
}

.networking-video-play-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 300px;
  height: 300px;
}

.industria-stands-area-container {
  position: absolute;
  width: 100%;
  height: 40%;
  /*background-color:#f6dd00;*/
  bottom: 50px;
}



.stand-container {
  display: inline-block;
  width: 20%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.expo-menu-container {
  position: absolute;
  z-index: 200;
  text-align: center;
  top: 150px;
  width: 100%;
}

.expo-menu-wrapper {
  padding: 10px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  display: inline-block;
  background-color: #ffffff;
  text-align: center;
}

.expo-menu-entry {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 28px;
  text-align: left;
  display: inline-block;
  min-width: 200px;
  height: 34px;
  border-right: 1px solid #000000;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.expo-menu-entry.selected {
  font-weight: bold;
}

.expo-menu-entry:last-child {
  border-right: none;
}

.stand-detail-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 200px);
  top: 170px;
  text-align: center;
  display: flex;
}

.stand-detail-background {
  display: inline-block;
  text-align: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
}

/*
.poster-left-stand-type2-container{
  position:absolute;
  z-index:200;
  width:7.6%;
  height:35.3%;
  background-color:#f6dd00;
  left:12.7%;
  top:44%;
  
}*/

.video-gallery-wrapper.voting-wrapper {
  max-width: 600px !important;
  margin: auto;
  margin-top: 100px;
}

.voting-form-wrapper {
  /*margin: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 800px;*/
}

.video-gallery-container {
  position: absolute;
  z-index: 300;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.video-gallery-wrapper {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  height: calc(100% - 300px);
  overflow-y: auto;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 18px;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.video-gallery-wrapper .video-gallery-title {
  font-size: 35px;
  font-weight: bold;
}

.video-gallery-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.video-gallery-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.video-gallery-wrapper::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.videos-container {
  margin-top: 50px;
  text-align: left;
}

.video-wrapper-entry {
  display: inline-block;
  width: 350px;
  min-width: 350px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.video-wrapper-entry:hover .video-preview-text-container,
.video-wrapper-entry:hover .video-preview-icons-container {
  background-color: #008457;
}

.video-wrapper-entry:hover {
  width: 98%;
}

.video-wrapper-entry:hover .video-preview-container {
  height: 220px;
}

.video-wrapper-entry:hover .video-preview-text-container {
  font-size: 14px;
}

.video-preview-container {
  height: 180px;
  background-color: #ff1d25;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  cursor: pointer;
}

.video-preview-container .video-preview-stand-play-icon {
  max-height: 40px;
  margin-top: 70px;
}

.video-preview-text-container {
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;
  max-height: 55px;
  overflow: hidden;
}

.video-preview-icons-container {
  text-align: right;
  background-color: #1a1a1a;
  padding: 5px;
}

.video-preview-icons-container img {
  max-height: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.video-gallery-entry-container {
  width: 25%;
  min-width: 380px;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  float: left;
}

.video-gallery-newsletter-container {
  position: absolute;
  bottom: 40px;
  left: 100px;
  font-family: "MyriadPro";
  font-size: 12px;
  text-align: left;
}

.video-gallery-newsletter-wrapper {
  margin-top: 5px;
}

.newsletter-input {
  position: relative;
  float: left;
}

.newsletter-input input {
  height: 28px;
  width: 200px;
  border: 0px;
  padding-left: 5px;
}

.newsletter-input input:focus {
  outline: none;
}

.newsletter-btn {
  position: relative;
  float: left;
  cursor: pointer;
}

.video-gallery-newsletter-container .video-gallery-newsletter-title {
  font-size: 12px;
  font-weight: bold;
}

.video-gallery-newsletter-container a {
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  color: #808080;
}

.video-gallery-close-btn-container {
  position: absolute;
  bottom: 40px;
  right: 100px;
  width: 150px;
  padding: 5px;
  background-color: #333333;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.video-player-container {
  position: absolute;
  width: 900px;

  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.video-player-wrapper {
  background-color: #00a99d;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.video-player-video {
  height: 450px;
  background-color: #000000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.video-player-video-desc {
  padding: 15px;
  text-align: left;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #333333;
}

.video-player-video-bar {
  position: absolute;
  top: 350px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100px;
}

.video-player-bottom-icons-container {
  padding: 10px;
  text-align: right;
  margin-top: 10px;
}

.video-player-bottom-icons-container img {
  margin-left: 20px;
  max-height: 25px;
  cursor: pointer;
}

.flyer-gallery-entry-container {
  width: 20%;
  min-width: 215px;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  float: left;
}

.flyer-gallery-entry-container img {
  max-height: 250px;
  cursor: pointer;
}

.flyer-gallery-entry-container .flyer-wrapper:hover img {
  max-height: 300px;
}

.flyer-gallery-entry-container .flyer-wrapper:hover .flyer-description {
  display: block;
}

.flyer-wrapper {
  position: relative;
}

.flyer-description {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-family: "MyriadPro";
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  display: none;
}

.flyer-description-wrapper {
  padding: 5px;
}

.flyer-viewer-container {
  text-align: center;
}

.flyer-viewer-entry {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 560px;
}

.flyer-viewer-entry-bottom-icons {
  padding: 10px;
  text-align: right;
}

.flyer-viewer-entry-bottom-icons img {
  max-height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.video-flyer-gallery-company-logo {
  height: 80px;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
  text-align: right;
}

.video-flyer-gallery-company-logo img {
  max-height: 80px;
}

.stand-detail-btns-view-container {
  position: absolute;
  left: 50px;
  bottom: 40px;
  z-index: 200;
}

.stand-detail-btn-view-wrapper {
  width: 80px;
  height: 90px;
  position: relative;
  float: left;
  background-image: url(/img/back-bottom-btns-stand.detail-view.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  text-align: center;
  padding: 5px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  cursor: pointer;
  margin-right: 50px;
}

.stand-detail-btn-view-wrapper img {
  margin-top: 10px;
  margin-bottom: 5px;
  max-width: 50px;
}

.assistant-stand-detail-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 800;
  text-align: center;
  width: 650px;
}

.assistant-stand-detail-option-container {
  display: inline-block;
  width: 200px;
  padding: 8px;
  background-color: #008457;
  margin-bottom: 0px;
  margin-left: -4px;
  margin-right: -4px;
  font-family: "MyriadPro";
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.assistant-stand-detail-option-container img {
  max-height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.assistant-stand-detail-status-container {
  background-color: #f2f2f2;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 0px;
  padding: 10px;
}

.assistant-stand-detail-status-title {
  font-family: "MyriadPro";
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.switch-assistant-stand-detail-status {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 12px;
}

.switch-assistant-stand-detail-status .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 15px;
}

.switch-assistant-stand-detail-status .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-assistant-stand-detail-status .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e6e6e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 8px;
  line-height: 2;
  color: #acacac;
}

.switch-assistant-stand-detail-status .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: #39b54a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-assistant-stand-detail-status input:checked+.slider {
  background-color: #e6e6e6;
}

.switch-assistant-stand-detail-status input:focus+.slider {
  box-shadow: 0 0 1px #e6e6e6;
}

.switch-assistant-stand-detail-status input:checked+.slider:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  background-color: #ed1c24;
}

/* Rounded sliders */
.switch-assistant-stand-detail-status .slider.round {
  border-radius: 24px;
}

.switch-assistant-stand-detail-status .slider.round:before {
  border-radius: 50%;
}

.assistant-stand-detail-image-container {
  margin-bottom: -3px;
  text-align: center;
  position: relative;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.assistant-stand-detail-image-container img {
  max-height: 130px;
}

.assistant-stand-audio-btn-container {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 200;
  width: 42px;
  height: 40px;
}

.assistant-stand-audio-btn-container img {
  max-height: 40px;
}

.stand-detail-img {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto;
  max-height: 100%;
  margin: auto;
}

.mapcontainer {
  position: absolute;
  z-index: 200;
  display: flex;
  cursor: pointer;
}

.mapcontainer img:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform .2s;
}

.mapcontainer img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  margin: auto;
}

.stand-detail-colors-container {
  position: absolute;
  z-index: 300;
  width: 100%;
  top: 140px;
  height: calc(100% - 140px);
}

.area-color-stand-detail {
  position: absolute;
  display: flex;
  font-family: "MyriadPro";
  font-size: 3.5vw;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 1px #000000;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.area-color-stand-detail.yellow {
  background-color: rgba(252, 255, 0, 0.8);
}

.area-color-stand-detail.yellow:hover {
  background-color: rgba(252, 255, 0, 1);
}

.mapcontainer-img-margin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

/* .mapcontainer-img-margin img {
  max-width: 80%;
} */

#StandType2-map5,
#StandType2-map6,
#StandType2-map7,
#StandType2-map8 {
  /*-moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;*/
}

.nocontent {
  -moz-box-shadow-bottom: 0px 0px 0px !important;
  -webkit-box-shadow-bottom: 0px 0px 0px !important;
  box-shadow: 0px 0px 0px !important;
}

.eposter-search-filter-bar-container {
  position: absolute;
  width: 100%;
  top: 170px;
  text-align: center;
}

.eposter-search-filter-bar {
  /*display:inline-block;*/
  position: relative;
  float: left;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  margin: 0 auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.eposter-search-icon-magnifier {
  position: relative;
  float: left;
  margin-top: 3px;
}

.eposter-search-icon-magnifier img {
  max-height: 20px;
  cursor: pointer;
}

.eposter-search-input {
  position: relative;
  float: left;
  margin-top: 3px;
}

.eposter-search-input input {
  width: 250px;
  border: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.eposter-search-input input:focus {
  outline: none;
}

.eposter-search-icon-close {
  position: relative;
  float: left;
  margin-top: 3px;
}

.eposter-search-icon-close img {
  max-height: 20px;
  cursor: pointer;
}

.eposter-search-filter-separator {
  width: 1px;
  height: 25px;
  background-color: #000000;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  float: left;
}

.switch-eposter-search-filter {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 12px;
  position: relative;
  float: left;
  margin-right: 20px;
  margin-top: 5px;
}

.switch-eposter-search-filter.last {
  margin-right: 0px !important;
}

.switch-eposter-search-filter .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch-eposter-search-filter .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-eposter-search-filter .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 8px;
  line-height: 2;
  color: #acacac;
}

.switch-eposter-search-filter .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: #e6e6e6;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-eposter-search-filter input:checked+.slider {
  background-color: #39b54a;
}

.switch-eposter-search-filter input:focus+.slider {
  box-shadow: 0 0 1px #e6e6e6;
}

.switch-eposter-search-filter input:checked+.slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.switch-eposter-search-filter .slider.round {
  border-radius: 24px;
}

.switch-eposter-search-filter .slider.round:before {
  border-radius: 50%;
}

.eposter-breadcrumbs-container {
  position: absolute;
  top: 170px;
  left: 150px;
  right: 150px;
  width: auto;
  z-index: 250;
}

.eposter-breadcrumbs-container img {
  max-height: 20px;
  cursor: pointer;
}

.eposter-list-content-container {
  position: absolute;
  top: 250px;
  width: auto;
  left: 150px;
  right: 150px;
  z-index: 250;
  margin: auto;
  height: calc(100% - 310px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-list-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.eposter-list-content-container::-webkit-scrollbar {
  width: 12px;
  background-color: #4d4d4d;
}

.eposter-list-content-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.eposter-category-container {
  position: relative;
  float: left;
  padding: 15px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  margin-right: 30px;
  margin-bottom: 25px;
  width: 320px;
  height: 140px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
}

.eposter-category-title-container {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  word-wrap: break-word;
}





.eposter-category-bottom-container {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  width: auto;
}

.eposter-category-bottom-number {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 35px;
}

.eposter-category-bottom-logo {
  position: relative;
  float: left;
}

.eposter-category-bottom-logo img {
  /*max-height: 35px;*/
  max-height: 50px;
}

.eposter-entry-container {
  width: 480px;
  height: 200px;
  background-color: #ffffff;
  position: relative;
  float: left;
  margin-right: 30px;
  margin-bottom: 25px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  cursor: pointer;
}

.eposter-entry-container.sefc2022 {
  height: 340px;
}

.sefc2022 .eposter-entry-content-title-authors {
  height: 270px;
  max-height: 270px;
}

.eposter-entry-cat-color {
  width: 20px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.eposter-entry-content-container {
  position: absolute;
  width: auto;
  height: auto;
  left: 20px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  padding: 10px;
}

.eposter-entry-content-title-authors {
  position: relative;
  float: left;
  width: 310px;
  height: 130px;
  max-height: 130px;
}



.eposter-entry-content-title-authors .eposter-entry-title {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
}

.eposter-entry-content-title-authors .eposter-entry-authors {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
}

.eposter-entry-content-poster-preview {
  position: relative;
  float: right;
  width: 113px;
  height: 130px;
  max-height: 130px;

  border: 1px solid #d8d8d8;
  text-align: right;
}

.eposter-entry-content-poster-preview img {
  max-height: 130px;
  max-width: 113px;
}

.eposter-entry-bottom-container {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

.eposter-entry-rating-container {
  position: relative;
  float: left;
  margin-top: 20px;
}

.eposter-entry-views-container {
  position: relative;
  float: left;
  margin-left: 20px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  margin-top: 25px;
}

.eposter-entry-comments-container {
  position: relative;
  float: left;
  margin-left: 20px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  margin-top: 23px;
}

.eposter-entry-number {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 33px;
  margin-top: 5px;
}

.eposter-breadcrumbs-home-icon {
  position: relative;
  float: right;
}

.eposter-breadcrumbs-home-icon img {
  max-height: 30px !important;
}

.eposter-breadcrumbs-path-text {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  color: #f2f2f3;
  font-size: 33px;
  font-weight: bold;
  margin-left: 20px;
}

.eposter-breadcrumbs-category {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  float: right;
  margin-left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.eposter-content-container {
  position: relative;
  width: 100%;
}

.eposter-discussion-links-container {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
}

.eposter-discussion-link {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  float: left;
  margin-right: 20px;
  padding-left: 10px;
  padding-right: 10px;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
  background-color: rgb(192, 39, 45);
  cursor: pointer;
}

.left-column-cont-eposter-detail {
  margin-right: 560px;
  /* The size of the fixed width column + space between them 
      + sum of padding used in both columns. */
  height: 500px;
}

.left-column-eposter-detail {
  background-color: #ffd800;
  width: 100%;
  float: left;
  height: 100%;
  margin-right: -560px;
  /* The size of the fixed width column + space between them.
      + sum of padding used in both columns. 
      IMPORTANT! use negative margin here. */
  margin-left: 50px;
  margin-top: 50px;
}

.right-column-eposter-detail {
  float: right;
  width: 440px;
  /* The size of the fixed width column */
  height: calc(100% - 50px);
  margin-right: 0px;
  background-color: #ff0000;
  margin-top: 50px;
}

.eposter-detail-top-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: auto;
  height: 50px;
}

.eposter-detail-left-content-container {
  position: absolute;
  top: 30px;
  left: 30px;
  right: 480px;
  width: auto;
  height: calc(100% - 30px);
}

.eposter-detail-left-content-container.full-eposter {
  right: 0px;
}

.eposter-detail-right-content-container {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 500px;
  height: calc(100% - 50px);
}

.eposter-detail-right-content-container.full-eposter {
  display: none;
}

.eposter-detail-top-category {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 20px;
  /* text-transform: uppercase; */
  position: relative;
  float: left;
  margin-left: 50px;
  margin-top: 10px;
  white-space: nowrap;
}

.eposter-detail-top-company-container {
  position: relative;
  float: left;
  height: 50px;
  margin-left: 20%;
}

.eposter-detail-top-company-text {
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 12px;
  position: relative;
  float: left;
  /* margin-top: 24px; */
  height: 40px;
}

.eposter-detail-top-company-logo {
  position: relative;
  float: left;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
}

.eposter-detail-top-company-logo img {
  max-height: 40px;
}

.eposter-detail-top-rating-views-comments-close-container {
  height: 50px;
  min-width: 100px;
  background-color: #60378c;
  padding-left: 20px;
  padding-right: 50px;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 200;
}

.eposter-detail-top-rating-container {
  position: relative;
  float: left;
  margin-top: 24px;
}

.eposter-detail-top-rating-container img {
  max-height: 14px;
  float: left;
}

.eposter-detail-top-views-comments-container {
  position: relative;
  float: left;
  margin-top: 26px;
  margin-left: 20px;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 14px;
}

.eposter-detail-top-views-comments-container img {
  max-height: 12px;
}

.eposter-detail-top-close-btn-container {
  position: relative;
  float: left;
  margin-left: 30px;
  margin-top: 15px;
}

.eposter-detail-top-close-btn {
  position: relative;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #333333;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 14px;

  text-align: center;
  border: 1px solid #ffffff;
  cursor: pointer;
  text-transform: uppercase;
}

.eposter-detail-video-container {
  width: 500px;
  height: 290px;
  background-color: #FFF;
}

.eposter-detail-text-background-container {
  height: calc(100% - 340px);
  background-color: #f2f2f2;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.eposter-detail-text-background-container-no-video {
  height: calc(100% - 40px);
  background-color: #f2f2f2;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.chat-on .eposter-detail-video-container {
  height: 45%;
}

.chat-on .eposter-detail-text-background-container {
  height: calc(55% - 40px);
}

.video-recording-controls-container {
  height: 50px;
  background-color: #373737;
  position: absolute;
  z-index: 300;
  left: 0px;
  right: 0px;
  width: auto;
  display: none;
  text-align: center;
}

.video-recording-controls-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 7px;
}

.recording-on .video-recording-controls-container {
  display: block;
}

.eposter-detail-btns-container {
  padding-top: 0px;
}

.recording-on .eposter-detail-btns-container {
  padding-top: 60px;
}

.recording-on .eposter-detail-text-background-container {
  padding-top: 0px;
  height: calc(70% - 20px);
}

.recording-on .eposter-detail-text-background-container-no-video {
  padding-top: 0px;
  height: calc(100% - 20px);
}

.recording-on .eposter-detail-text-wrapper {
  height: calc(100% - 100px);
}

.recording-on .eposter-detail-text-container {
  height: calc(100% - 70px);
}

.eposter-detail-text-btns-container {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #333333;
  font-size: 12px;
  cursor: pointer;
}

.eposter-detail-text-btns-container img {
  max-height: 15px;
}

.eposter-detail-text-btns-separator {
  position: relative;
  float: left;
  width: 1px;
  height: 20px;
  background-color: #333333;
  margin-left: 20px;
  margin-right: 20px;
}

.eposter-detail-text-wrapper {
  margin-top: 20px;
  height: calc(100% - 80px);
}

.eposter-detail-text-tab {
  position: relative;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "MyriadPro";
  color: #333333;
  font-size: 25px;
}

.eposter-detail-text-tab.selected {
  background-color: #ffffff;
}

.eposter-detail-text-container {
  background-color: #ffffff;
  height: calc(100% - 30px);
  padding: 15px;
  /* max-height: 520px; */
  overflow-y: auto;
  font-family: "MyriadPro";
  color: #333333;
  font-size: 14px;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-detail-text-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.eposter-detail-text-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.eposter-detail-text-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.eposter-detail-text-container .eposter-detail-text-title {
  text-transform: uppercase;
  font-weight: bold;
}


.eposter-detail-text-container .eposter-detail-text-title.guest-society {
  font-size: 20px;
}

.eposter-detail-text-container .eposter-detail-text-author {
  font-style: italic;
  /* text-decoration: underline; */
  position: relative;
  float: left;
  margin-right: 10px;
  padding-top: 5px;
  padding-right: 12px;
  margin-bottom: 5px;
  cursor: pointer;
}

.eposter-detail-text-author-availability {
  position: absolute;
  top: 0;
  right: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #cccccc;
}

.eposter-detail-text-author-availability.on {
  background-color: #39b54a;
}

.eposter-detail-controls-container {
  position: absolute;
  height: 80px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.eposter-detail-control-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.eposter-detail-control-wrapper img {
  max-width: 20px;
}

.eposter-viewer-container {
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 0px;
  right: 50px;
  width: auto;
  height: auto;
  background-color: #000000;
  z-index: 500;
  border: 10px solid #000000;
}

.eposter-viewer-wrapper {
  width: auto;
  height: auto;
  border-radius: 10px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
  cursor: grab;
  cursor: -o-grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-viewer-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  display: none;
}

.eposter-viewer-wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  display: none;
}

.eposter-viewer-wrapper::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  display: none;
}

.eposter-viewer-wrapper img {
  max-width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.eposter-detail-chat-container {
  position: absolute;
  height: 45%;
  width: auto;
  background-color: #f6dd00;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 200;
}

.eposter-detail-chat-header {
  height: 30px;
  background-color: #333333;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.eposter-detail-icon-network {
  position: relative;
  float: left;
  margin-top: 5px;
}

.eposter-detail-sponsored-text {
  position: relative;
  float: left;
  margin-top: 10px;
  font-family: "MyriadPro";
  font-size: 12px;
  color: #ffffff;
  margin-left: 5px;
}

.eposter-detail-company-logo {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}

.eposter-detail-company-logo img {
  max-height: 25px;
}

.eposter-detail-close-icon {
  position: relative;
  float: right;
  margin-top: 10px;
}

.eposter-detail-chat-subheader {
  height: 30px;
  background-color: #f2f2f2;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5d5d5;
}

.eposter-detail-chat-avatar {
  position: relative;
  float: left;
}

.eposter-detail-chat-avatar img {
  border-radius: 50%;
  max-height: 25px;
}

.eposter-detail-chat-avatar-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 13px;
  margin-left: 5px;
}

.eposter-detail-chat-avatar-text .eposter-detail-name {
  font-weight: bold;
  color: #424242;
}

.eposter-detail-chat-avatar-text .eposter-detail-desc {
  color: #8f8f8f;
}

.eposter-detail-chat-video-icon {
  position: relative;
  float: right;
  margin-right: 20px;
  margin-top: 5px;
}

.eposter-detail-chat-video-icon img {
  max-height: 15px;
}

.eposter-detail-chat-call-icon {
  position: relative;
  float: right;
  margin-top: 5px;
}

.eposter-detail-chat-call-icon img {
  max-height: 15px;
}

.eposter-detail-chat-area {
  height: calc(100% - 142px);
  /*max-height:500px;*/
  background-color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-detail-chat-area::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.eposter-detail-chat-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.eposter-detail-chat-area::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.eposter-detail-assistant-chat-text {
  position: relative;
  float: left;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 15px;
  background-color: #ffffff;
  max-width: 280px;
}

.eposter-detail-person-chat-text {
  position: relative;
  float: right;
  box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 -6px 0 #fff, 0 1px 6px rgba(0, 0, 0, 0.35);
  padding: 8px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #4d4d4d;
  margin-top: 15px;
  background-color: #e7fbec;
  max-width: 280px;
}

.eposter-detail-person-chat-text:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.eposter-detail-chat-footer {
  height: 30px;
  background-color: #f2f2f2;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.eposter-detail-chat-input {
  position: relative;
  float: left;
}

.eposter-detail-chat-input input {
  height: 20px;
  width: 400px;
  border-radius: 5px;
  padding-left: 10px;
  border: 2px solid #d3d3d7;
}

.eposter-detail-chat-input input:focus {
  outline: none;
}

.eposter-detail-chat-send-button {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-top: 4px;
}

.eposter-detail-chat-send-attach {
  position: relative;
  float: right;
  margin-top: 5px;
}

.eposter-detail-chat-send-attach img {
  max-height: 20px;
}

.eposter-detail-chat-send-photo {
  position: relative;
  float: right;
  margin-top: 10px;
  margin-right: 15px;
}

.eposter-detail-chat-send-photo img {
  max-height: 15px;
}

.backdrop-eposter-detail {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 800;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
}

.eposter-detail-send-message-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  max-width: 600px;
  max-height: 60%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-detail-send-message-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.eposter-detail-send-message-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.eposter-detail-send-message-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.eposter-detail-send-message-header-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 29px;
  font-family: "MyriadPro";
  font-size: 22px;
  color: #000000;
  text-align: left;
  border-bottom: 1px solid #999999;
}

.eposter-detail-send-message-close-icon-container {
  position: relative;
  float: right;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.eposter-detail-send-message-close-icon-container img {
  max-width: 10px;
}

.eposter-detail-send-message-footer-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #000000;
  text-align: right;
}

.eposter-detail-send-message-area-container {
  height: calc(100% - 100px);
}

.eposter-detail-send-message-btn-wrapper {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
}

.eposter-detail-send-message-btn-wrapper img {
  max-height: 22px;
  margin-left: 5px;
}

.eposter-detail-send-message-persons-sub-header-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  height: 80px;
}

.eposter-detail-message-arrow-container {
  display: inline-block;
  margin-top: 40px;
}

.eposter-detail-send-message-attendee-wrapper {
  position: relative;
  float: left;
  text-align: left;
}

.eposter-detail-send-message-avatar {
  position: relative;
  float: left;
}

.eposter-detail-send-message-avatar img {
  border-radius: 50%;
  max-height: 80px;
}

.eposter-detail-send-message-person-name {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #333333;
  margin-top: 40px;
}

.eposter-detail-send-message-attendee-wrapper .eposter-detail-send-message-person-name {
  margin-left: 10px;
}

.eposter-detail-send-message-poster-manager-wrapper {
  position: relative;
  float: right;
  text-align: right;
}

.eposter-detail-send-message-poster-manager-wrapper .eposter-detail-send-message-person-name {
  margin-right: 10px;
}

.eposter-detail-message-subject-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f2f2f2;
}

.eposter-detail-message-subject-container input {
  width: 100%;
  font-size: 20px;
  font-family: "MyriadPro";
  color: #000000;
  background-color: #f2f2f2;
  border: 0px;
  font-weight: bold;
}

.eposter-detail-message-subject-container input:focus {
  outline: none;
}

.eposter-detail-message-textarea-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f2f2f2;
  margin-top: 20px;
  height: calc(100% - 196px);
}

.eposter-detail-message-textarea-container textarea {
  width: 100%;
  height: 100%;
  border: 0px;
  background-color: #f2f2f2;
  font-family: "MyriadPro";
  color: #000000;
  background-color: #f2f2f2;
  font-size: 18px;
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-detail-message-textarea-container textarea:focus {
  outline: none;
}

.eposter-detail-message-textarea-container textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.eposter-detail-message-textarea-container textarea::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.eposter-detail-message-textarea-container textarea::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.eposter-detail-comments-area-container {
  height: calc(100% - 110px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.eposter-detail-comments-area-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.eposter-detail-comments-area-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.eposter-detail-comments-area-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.eposter-detail-comments-footer-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  text-align: left;
  background-color: #f2f2f2;
}

.eposter-detail-comments-footer-input-container {
  padding: 5px;
  height: 25px;
  background-color: #ffffff;
}

.eposter-detail-comments-btn-send-container {
  position: relative;
  float: right;
  cursor: pointer;
}

.eposter-detail-comments-btn-send-container img {
  max-height: 20px;
  margin-top: 2px;
}

.eposter-detail-comments-input-container {
  position: relative;
  float: left;
  width: calc(100% - 40px);
}

.eposter-detail-comments-input-container input {
  border: 0px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  width: 100%;
}

.eposter-detail-comments-input-container input:focus {
  outline: none;
}

.eposter-detail-comments-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #999999;
  /*min-height:100px;*/
}

.eposter-detail-comments-wrapper:last-child {
  border-bottom: 0px solid #999999;
}

.eposter-detail-comment-person-container {
  min-height: 80px;
}

.eposter-detail-comment-text {
  padding-left: 90px;
  margin-top: 10px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
}

.eposter-detail-comment-reply-btn-container {
  margin-top: 10px;
  text-align: right;
}

.eposter-detail-comment-reply-btn-wrapper {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}

.eposter-author-details-container {
  position: absolute;
  z-index: 900;
  width: 170px;
  background-color: #ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  left: 0px;
  background-color: #ffffff;
  display: none;
  padding: 10px;
  text-align: center;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  min-height: 250px;
}

.eposter-author-detail-avatar {
  width: 70px;
  height: 70px;
  margin: auto;
  text-align: center;
}

.eposter-author-detail-avatar img {
  border-radius: 50%;
  max-height: 70px;
  max-width: 70px;
}

.eposter-author-details-container .author-name {
  font-size: 18px;
  font-weight: bold;
}

.eposter-author-details-container .author-institution {
  font-size: 12px;
}

.author-text {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.eposter-author-detail-send-message-btn-container {
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  width: auto;
  height: 30px;
  text-align: center;
}

.eposter-author-detail-send-message-btn-wrapper {
  display: inline-block;
  height: 30px;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 14px;
  margin: 0 auto;
  cursor: pointer;
}

.eposter-author-detail-send-message-btn-wrapper img {
  max-height: 18px;
}

.eposter-detail-rating-container {
  position: absolute;
  width: 180px;
  height: 200px;
  /*background-color:#ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;*/
  z-index: 300;
  bottom: -205px;
  left: 0px;
}

.eposter-detail-rating-container.closed {
  display: none;
}

.eposter-detail-rating-content-container {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding: 5px;
}

.eposter-detail-rating-arrow-container {
  height: 11px;
}

.eposter-detail-rating-arrow-container img {
  margin-left: 30px;
}

.eposter-detail-rating-starts-container {
  position: relative;
  float: left;
  height: 18px;
  margin-bottom: 5px;
}

.eposter-detail-rating-starts-separator-container {
  position: relative;
  float: left;
  height: 13px;
  width: 60px;
  border-bottom: 1px solid #000000;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.eposter-detail-rating-starts-number-container {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  margin-top: 2px;
  margin-bottom: 5px;
}

.eposter-detail-rating-text {
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  text-transform: uppercase;
}

.eposter-detail-user-rate-start-container {
  position: relative;
  float: left;
  width: 20px;
  height: 18px;
  background-image: url(/img/eposter-rating-star-grey.png);
  cursor: pointer;
}

.eposter-detail-user-rate-start-container:hover {
  background-image: url(/img/eposter-rating-star-yellow.png);
}

.eposter-detail-rating-btn {
  float: right;
  position: relative;
  font-family: "MyriadPro";
  color: #000000;
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
}

.video-start-recording-btn-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.video-start-recording-btn-red-dot-container {
  position: relative;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: #ff1d25;
  display: inline-block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.video-start-recording-btn-wrapper:hover .video-start-recording-btn-red-dot-container {
  width: 45%;
  height: 45%;
}

.video-stop-pause-btn-wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: relative;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;
}

.video-stop-pause-btn-wrapper img {
  max-height: 55%;
  max-width: 55%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.video-stop-pause-btn-wrapper:hover img {
  max-height: 45%;
  max-width: 45%;
}

.video-time-recording-container {
  position: relative;
  float: right;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 26px;
  margin-left: 10px;
  margin-top: 5px;
}

.video-recording-timer-dot {
  position: absolute;
  top: 0px;
  right: -10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff1d25;
  z-index: 300;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.eposter-detail-top-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0px;
  left: 0px;
}

.total-rows-3 .industria-stands-row1 .stand-container {
  margin-left: 2.3%;
  margin-right: 2.3%;
}

.total-rows-3 .industria-stands-row2 .stand-container {
  margin-left: -1%;
  margin-right: -1%;
}

.total-rows-3 .industria-stands-row3 .stand-container {
  margin-left: -2%;
  margin-right: -2%;
}


.total-rows-3 .industria-stands-row1 {
  position: absolute;
  width: 100%;
  z-index: 800;
  bottom: 0;
  height: 45%;
  text-align: center;
}


.total-rows-3 .industria-stands-row2 {
  position: absolute;
  width: 100%;
  z-index: 600;
  bottom: 45%;
  height: 38%;
  text-align: center;
}


.total-rows-3 .industria-stands-row3 {
  position: absolute;
  width: 100%;
  z-index: 400;
  bottom: 80%;
  height: 30%;
  text-align: center;

}



.total-rows-3 .stand-container.type1 {
  background-image: url(/img/stand-type1.png);
}


.total-rows-3 .type1 .stand-sponsor-container {
  position: absolute;
  top: -28%;
  /*/width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.total-rows-3 .industria-stands-row2 .stand-sponsor-container {
  width: 35% !important;
  height: 35% !important;
}



.total-rows-3 .industria-stands-row3 .stand-sponsor-container {
  width: 25% !important;
  height: 35% !important;
}


.total-rows-3 .stand-container.type2 {
  background-image: url(/img/stand-type2.png);
}

.stand-sponsor-container:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform .2s;
}

.total-rows-3 .type2 .stand-sponsor-container {
  position: absolute;
  top: -28%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 17%;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;

}

.total-rows-3 .industria-stands-row2 .type2 .stand-sponsor-container {
  left: 24%;
}

.total-rows-3 .industria-stands-row3 .type2 .stand-sponsor-container {
  left: 31%;
}

.total-rows-3 .stand-container.type3 {
  background-image: url(/img/stand-type3.png);
}



.total-rows-3 .type3 .stand-sponsor-container {
  position: absolute;
  top: -28%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}




.total-rows-3 .stand-container.type4 {
  background-image: url(/img/stand-type4.png);
}


.total-rows-3 .type4 .stand-sponsor-container {
  position: absolute;
  top: -28%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}





.total-rows-2 .industria-stands-row1 .stand-container {
  margin-left: 2.3%;
  margin-right: 2.3%;
}

.total-rows-2 .industria-stands-row2 .stand-container {
  margin-left: 0.5%;
  margin-right: 1%;
}

.total-rows-2 .industria-stands-row3 .stand-container {
  margin-left: -2%;
  margin-right: -2%;
}



.total-rows-2 .industria-stands-row1 {
  position: absolute;
  width: 100%;
  z-index: 800;
  bottom: 10%;
  height: 55%;
  text-align: center;
}


.total-rows-2 .industria-stands-row2 {
  position: absolute;
  width: 100%;
  z-index: 600;
  bottom: 70%;
  height: 47%;
  text-align: center;
}


.total-rows-2 .industria-stands-row3 {
  position: absolute;
  width: 100%;
  z-index: 400;
  bottom: 80%;
  height: 30%;
  text-align: center;
  display: none;
}


.total-rows-2 .stand-container.type1 {
  background-image: url(/img/stand-type1.png);
}


.total-rows-2 .type1 .stand-sponsor-container {
  position: absolute;
  top: -25%;
  /*/width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.total-rows-2 .industria-stands-row2 .stand-sponsor-container {
  width: 35% !important;
  height: 35% !important;
  top: -28%;
}



.total-rows-2 .industria-stands-row3 .stand-sponsor-container {
  width: 25% !important;
  height: 35% !important;
  top: -28%;
}


.total-rows-2 .stand-container.type2 {
  background-image: url(/img/stand-type2.png);
}


.total-rows-2 .type2 .stand-sponsor-container {
  position: absolute;
  top: -25%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 17%;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;

}

.total-rows-2 .industria-stands-row2 .type2 .stand-sponsor-container {
  left: 22%;
  top: -29%;
}

.total-rows-2 .industria-stands-row3 .type2 .stand-sponsor-container {
  left: 31%;
  top: -29%;
}

.total-rows-2 .stand-container.type3 {
  background-image: url(/img/stand-type3.png);
}



.total-rows-2 .type3 .stand-sponsor-container {
  position: absolute;
  top: -25%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 3%;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}


.total-rows-2 .industria-stands-row2 .type3 .stand-sponsor-container {
  left: 3%;
  top: -29%;
}

.total-rows-2 .industria-stands-row3 .type3 .stand-sponsor-container {
  left: 3%;
  top: -29%;
}

.total-rows-2 .stand-container.type4 {
  background-image: url(/img/stand-type4.png);
}


.total-rows-2 .type4 .stand-sponsor-container {
  position: absolute;
  top: -24%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.total-rows-2 .industria-stands-row2 .type4 .stand-sponsor-container {

  top: -28%;
}

.total-rows-2 .industria-stands-row3 .type4 .stand-sponsor-container {

  top: -29%;
}



.total-rows-1 .industria-stands-row1 .stand-container {
  margin-left: 2%;
  margin-right: 2%;
}

.total-rows-1 .industria-stands-row2 .stand-container {
  margin-left: 0.5%;
  margin-right: 1%;
}

.total-rows-1 .industria-stands-row3 .stand-container {
  margin-left: -2%;
  margin-right: -2%;
}



.total-rows-1 .industria-stands-row1 {
  position: absolute;
  width: 100%;
  z-index: 800;
  bottom: 25%;
  height: 60%;
  text-align: center;
}


.total-rows-1 .industria-stands-row2 {
  position: absolute;
  width: 100%;
  z-index: 600;
  bottom: 70%;
  height: 38%;
  text-align: center;
  display: none;
}


.total-rows-1 .industria-stands-row3 {
  position: absolute;
  width: 100%;
  z-index: 400;
  bottom: 80%;
  height: 30%;
  text-align: center;
  display: none;
}



.total-rows-1 .stand-container.type1 {
  background-image: url(/img/stand-type1.png);
}


.total-rows-1 .type1 .stand-sponsor-container {
  position: absolute;
  top: -21%;
  /*/width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.total-rows-1 .industria-stands-row2 .stand-sponsor-container {
  width: 35% !important;
  height: 35% !important;
  top: -28%;
}



.total-rows-1 .industria-stands-row3 .stand-sponsor-container {
  width: 25% !important;
  height: 35% !important;
  top: -28%;
}


.total-rows-1 .stand-container.type2 {
  background-image: url(/img/stand-type2.png);
}


.total-rows-1 .type2 .stand-sponsor-container {
  position: absolute;
  top: -21%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 17%;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;

}

.total-rows-1 .industria-stands-row2 .type2 .stand-sponsor-container {
  left: 22%;
  top: -29%;
}

.total-rows-1 .industria-stands-row3 .type2 .stand-sponsor-container {
  left: 31%;
  top: -29%;
}

.total-rows-1 .stand-container.type3 {
  background-image: url(/img/stand-type3.png);
}



.total-rows-1 .type3 .stand-sponsor-container {
  position: absolute;
  top: -21%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 3%;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}


.total-rows-1 .industria-stands-row2 .type3 .stand-sponsor-container {
  left: 3%;
  top: -29%;
}

.total-rows-1 .industria-stands-row3 .type3 .stand-sponsor-container {
  left: 3%;
  top: -29%;
}

.total-rows-1 .stand-container.type4 {
  background-image: url(/img/stand-type4.png);
}


.total-rows-1 .type4 .stand-sponsor-container {
  position: absolute;
  top: -21%;
  /*width:180px;
  height:80px;*/
  width: 45%;
  height: 35%;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
  -moz-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  -webkit-box-shadow-bottom: 4px 4px 4px #4d4c4c;
  box-shadow: 4px 4px 4px #4d4c4c;
}

.total-rows-1 .industria-stands-row2 .type4 .stand-sponsor-container {

  top: -28%;
}

.total-rows-1 .industria-stands-row3 .type4 .stand-sponsor-container {

  top: -29%;
}

.eposter-intro-text-container {
  position: absolute;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  color: black;
  font-family: MyriadPro;
  background-color: #FFF;
  padding: 10px;
}

.eposter-intro-text-container img {
  max-width: 400px;
  max-height: 50px;
}


.eposter-detail-sponsor-container {
  position: absolute;
  width: 300px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  font-family: MyriadPro;
  bottom: 0;
}

.eposter-detail-sponsor-container img {
  max-width: 180px;
  max-height: 35px;
  vertical-align: middle;
}

.eposter-intro-text-subtitle {
  font-size: 16px;
  color: black;
  font-family: MyriadPro;
}

.industria-intro-text-container {
  position: absolute;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: black;
  font-family: MyriadPro;
  background-color: #FFF;
  padding: 10px;
}

.industria-intro-text-subtitle {
  font-size: 14px;
  color: black;
  font-family: MyriadPro;
}

.e-posters-sponsor-container {
  position: absolute;
  top: calc(100% - 60px);
  width: auto;
  left: 150px;
  right: 150px;
  margin: auto;
  height: 60px;
  overflow-y: auto;
}

.e-posters-sponsor-container img {
  max-height: 35px;
  vertical-align: middle;
}

.access-denied {
  width: 300px;
  height: 100px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -170px;
  background-color: rgba(0, 0, 0, 0.9);
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 28px;
  color: #fff;
  overflow-y: auto;
  text-align: center;
  font-weight: bold;
}

.access-denied .sub-title {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

a.a-no-style {
  text-decoration: none;
}

.cursor-only {
  cursor: pointer;
}

.connectionStatus {
  padding-top: 20px;
  margin-left: 5%;
  font-size: 1.5em;
}

.subscriber {
  position: relative;
  float: left;
  width: 400px !important;
  height: 280px !important;
  padding: 8px;
}

.publisher {
  position: relative;
  float: left;
  padding: 8px;
}

.OTPublisherContainer {
  /* width: 400px !important;
  height: 300px !important;   */
}

.OTSubscriberContainer {
  /* width: 400px !important;
  height: 300px !important;   */
}

#error {
  color: red;
}

.open-poster-detail .eposter-breadcrumbs-container {
  display: none;
}

.open-poster-detail .eposter-list-content-container {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.contact-request-button-accept {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00a99d;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 10px;
}

.contact-request-button-decline {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ff1d25;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 10px;
}

.notification-highlight {
  background-color: white;
}

.sessions-list-sponsored-text {
  font-size: 13px;
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 0px;
  text-transform: none;
  font-weight: bold;
}

.sessions-list-live-text {
  background-color: #ff1d25;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 12px;
  padding: 3px;
  text-align: center;
  font-weight: bold;
}

.industria-logos-area-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 220px);
  top: 170px;
  text-align: center;

}

.industria-logos-area-container.\31 reunionvirtualareas2021,
.industria-logos-area-container.\31 REUNIONVIRTUALAREAS2021 {
  overflow: auto;
}

.industria-logos-area-container.separ2021,
.industria-logos-area-container.SEPAR2021 {
  overflow: auto;
}



.industria-logos-area-container.\31 reunionvirtualareas2021::-webkit-scrollbar-track,
industria-logos-area-container.\31 REUNIONVIRTUALAREAS2021::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.industria-logos-area-container.\31 reunionvirtualareas2021::-webkit-scrollbar,
.industria-logos-area-container.\31 REUNIONVIRTUALAREAS2021::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.industria-logos-area-container.\31 reunionvirtualareas2021::-webkit-scrollbar-thumb,
.industria-logos-area-container.\31 REUNIONVIRTUALAREAS2021::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}





.industria-logos-area-container.separ2021::-webkit-scrollbar-track,
industria-logos-area-container.SEPAR2021::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.industria-logos-area-container.separ2021::-webkit-scrollbar,
.industria-logos-area-container.SEPAR2021::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.industria-logos-area-container.separ2021::-webkit-scrollbar-thumb,
.industria-logos-area-container.SEPAR2021::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}




.industria-logos-area-wrapper {
  position: relative;
  width: 100%;

}


.industria-logos-area-container-row {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 50px;
}

.industria-logo-entry-container {
  /*width:200px;
  height:200px;*/
  display: inline-block;
  margin-left: 1.5%;
  margin-right: 1.5%;
  /*vertical-align:bottom;*/
  border: 4px solid #3c3d3e;
  background-color: #3c3d3e;
  margin-top: 10px;

}

.\31 reunionvirtualareas2021 .industria-logo-entry-container,
.\31 REUNIONVIRTUALAREAS2021 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent !important;
}

.\31 reunionvirtualareas2021 .industria-logo-entry-container:last-child,
.\31 REUNIONVIRTUALAREAS2021 .industria-logo-entry-container:last-child {
  visibility: hidden;
}

.\32 reunionvirtualareas2021 .industria-logo-entry-container,
.\32 REUNIONVIRTUALAREAS2021 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent !important;
}

.\33 reunionvirtualareas2022 .industria-logo-entry-container,
.\33 REUNIONVIRTUALAREAS2022 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent !important;
}

.\34 reunionvirtualareas2022 .industria-logo-entry-container,
.\34 REUNIONVIRTUALAREAS2022 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent !important;
}


.sefc2022 .industria-logo-entry-container,
.SEFC2022 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: transparent !important;
}


.separ2021 .industria-logo-entry-container,
.SEPAR2021 .industria-logo-entry-container {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  /*background-color: transparent !important;*/
  width: 300px;
  height: 75px;
  background-color: #ffffff;
}



.industria-logo-entry-container img {
  border-radius: 5px;
}

.row-1 .industria-logo-entry-container,
.row-1 .industria-logo-entry-container img {
  max-height: 80px;
}

.\31 reunionvirtualareas2021 .row-1 .industria-logo-entry-container,
.\31 reunionvirtualareas2021 .row-1 .industria-logo-entry-container img,
.\31 REUNIONVIRTUALAREAS2021 .row-1 .industria-logo-entry-container,
.\31 REUNIONVIRTUALAREAS2021 .row-1 .industria-logo-entry-container img {
  max-height: 60px;
}

.\32 reunionvirtualareas2021 .row-1 .industria-logo-entry-container,
.\32 reunionvirtualareas2021 .row-1 .industria-logo-entry-container img,
.\32 REUNIONVIRTUALAREAS2021 .row-1 .industria-logo-entry-container,
.\32 REUNIONVIRTUALAREAS2021 .row-1 .industria-logo-entry-container img {
  max-height: 60px;
}

.\33 reunionvirtualareas2022 .row-1 .industria-logo-entry-container,
.\33 reunionvirtualareas2022 .row-1 .industria-logo-entry-container img,
.\33 REUNIONVIRTUALAREAS2022 .row-1 .industria-logo-entry-container,
.\33 REUNIONVIRTUALAREAS2022 .row-1 .industria-logo-entry-container img {
  max-height: 60px;
}


.\34 reunionvirtualareas2022 .row-1 .industria-logo-entry-container,
.\34 reunionvirtualareas2022 .row-1 .industria-logo-entry-container img,
.\34 REUNIONVIRTUALAREAS2022 .row-1 .industria-logo-entry-container,
.\34 REUNIONVIRTUALAREAS2022 .row-1 .industria-logo-entry-container img {
  max-height: 60px;
}


.sefc2022 .row-1 .industria-logo-entry-container,
.sefc2022 .row-1 .industria-logo-entry-container img,
.SEFC2022 .row-1 .industria-logo-entry-container,
.SEFC2022 .row-1 .industria-logo-entry-container img {
  max-height: 75px;
}

.sefc2022 .row-2 .industria-logo-entry-container,
.sefc2022 .row-2 .industria-logo-entry-container img,
.SEFC2022 .row-2 .industria-logo-entry-container,
.SEFC2022 .row-2 .industria-logo-entry-container img {
  max-height: 75px;
}

.sefc2022 .row-3 .industria-logo-entry-container,
.sefc2022 .row-3 .industria-logo-entry-container img,
.SEFC2022 .row-3 .industria-logo-entry-container,
.SEFC2022 .row-3 .industria-logo-entry-container img {
  max-height: 75px;
}

.sefc2022 .industria-logos-area-container-row.row-1,
.SEFC2022 .industria-logos-area-container-row.row-1 {
  margin-bottom: 20px;
}

.sefc2022 .industria-logos-area-container-row.row-2,
.SEFC2022 .industria-logos-area-container-row.row-2 {
  margin-bottom: 20px;
}

.sefc2022 .industria-logos-area-container-row.row-3,
.SEFC2022 .industria-logos-area-container-row.row-3 {
  margin-bottom: 20px;
}


.separ2021 .row-1 .industria-logo-entry-container,
.separ2021 .row-1 .industria-logo-entry-container img,
.SEPAR2021 .row-1 .industria-logo-entry-container,
.SEPAR2021 .row-1 .industria-logo-entry-container img {
  max-height: 60px;
}




.row-2 .industria-logo-entry-container,
.row-2 .industria-logo-entry-container img {
  max-height: 130px;
}



.stand-disclaimer-container {
  position: absolute;
  z-index: 300;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  max-width: 600px;
  text-align: center;
}


.stand-disclaimer-close-btn-container {

  width: 150px;
  padding: 5px;
  background-color: #333333;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin: auto;
}

.btngroup {
  margin: auto;
  overflow: hidden;
  display: inline-block;
  text-align: center;
}

.btngroup .stand-disclaimer-close-btn-container {
  display: inline-block;

  margin-left: 5px;
  margin-right: 5px;
}

.stand-election-selection-btn-container {
  padding: 5px;
  background-color: #333333;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin: auto;
}

.btngroup .stand-election-selection-btn-container {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.stand-election-selection-btn-container.selected {
  background-color: #008457;
}

.session-survey-container {
  position: absolute;
  z-index: 300;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  max-width: 600px;
  overflow-y: auto;
  height: calc(100% - 200px);
}

.session-survey-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.session-survey-container::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.session-survey-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}


.session-user-voting-button {
  padding: 10px;
  margin: 5px;
  display: block;
  background-color: #00a99d;
  border-radius: 5%;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}


.backdrop-disclaimer-text {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6000;
  /*background-color: rgba(0, 0, 0, 0.6);*/
  background-color: #000000;
  top: 0px;
  left: 0px;
}


.form-my-credits.cnp2023,
.form-my-credits.CNP2023 {
  display: none;
}

.form-my-credits.cnp2024,
.form-my-credits.CNP2024 {
  display: none;
}



.form-my-credits.form-session-survey {

  margin-left: 0px;
  margin-right: 0px;
}

.form-my-credits {
  background-color: #f2f2f2;
  /*margin-left: 30px;
  margin-right: 30px;*/
  padding: 20px;
  font-size: 16px;
  -moz-box-shadow-bottom: 4px 4px 4px #929292;
  -webkit-box-shadow-bottom: 4px 4px 4px #929292;
  box-shadow: 4px 4px 4px #929292;
}

.form-my-credits label,
.form-my-credits select,
.form-my-credits textarea {
  width: 100%;
}

.form-my-credits label {
  font-weight: bold;
  font-size: 14px;
}

.form-my-credits select {
  height: 25px;
  border: 0px;
  margin-top: 5px;
}

.form-my-credits textarea {
  border: 0px;
  margin-top: 5px;
  resize: none;
}

.form-my-credits select:focus,
.form-my-credits textarea:focus {
  outline: none;
}

.form-my-credits .input-wrapper {
  margin-top: 15px;
}

.form-my-credits .btn-submit {
  padding: 5px;
  background-color: #00a99d;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 14px;
  text-align: center;
  min-width: 100px;
  border: 0px;
  cursor: pointer;
}

.form-my-credits .btn-submit:focus {
  outline: none;
}

.meeting-stream-container {
  float: left;
  width: 100%;
  /*height:700px;
  height:100%;  */
  margin-top: 40px;
  height: calc(100vh - 80px);
}

.meeting-list-content-container {
  position: relative;
  width: auto;
  margin: auto;
  height: calc(100%);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.meeting-list-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.meeting-list-content-container::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.meeting-list-content-container::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
}

.presenter-play-button {
  float: right;
  cursor: pointer;
}

.agenda-close-button {
  float: right;
  cursor: pointer;
}



.meeting-access-container {
  position: absolute;
  z-index: 300;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  max-width: 400px;
  text-align: center;
}

.form-meeting-access label {
  width: 100%;
  display: block;
  font-family: "MyriadPro";
  font-size: 18px;
  margin-bottom: 10px;
}

.form-meeting-access input {
  font-size: 16px;
  text-align: center;
}


.form-meeting-access input:focus {
  outline: none;
}


.form-meeting-access .btn-submit {
  width: 150px;
  padding: 5px;
  background-color: #333333;
  text-align: center;
  font-family: "MyriadPro";
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  margin: auto;
  border: 0px;
  cursor: pointer;
}


.form-meeting-access .input-wrapper {
  margin-bottom: 15px;
}



.eposter-discussion-modal-container {
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  text-align: center;
  width: 100%;
  height: 100%;
}


.zepellin-container {
  position: absolute;
  height: 300px;
  width: 100%;
  /*background-color: #01a89e;*/
  z-index: 30;
}


.zepellin-container .zeppelin-img-container {
  position: absolute;
  /*background-color: #ff0000;*/
  right: 100px;
  /*px to move*/
  top: 40px;
}

.tabs-my-bag .login-company-logo img {
  max-height: 50px;
}






.video-pills-content-container {
  position: absolute;
  top: 170px;
  width: auto;
  left: 150px;
  right: 150px;
  z-index: 250;
  margin: auto;
  height: calc(100% - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.video-pills-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.video-pills-content-container::-webkit-scrollbar {
  width: 12px;
  background-color: #4d4d4d;
}

.video-pills-content-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}


.video-pill-entries-row {
  position: relative;
  width: 100%;
  margin-bottom: 150px;
  float: left;
}


.video-pill-entry-container {
  position: relative;
  float: left;
  width: 45%;
  height: 20vw;
  background-color: #ffffff;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
}


.video-pill-entry-container p {
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
}


.video-pill-entry-logo {
  position: relative;
  width: 100%;
  max-height: 60px;
  margin-bottom: 10px;
}

.video-pill-entry-container img {
  max-height: 60px;
}

.video-pill-entry-video {
  position: relative;
  width: 100%;
  height: 100%;
}


.video-pill-entry-container iframe {
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.video-pill-entry-separator {
  position: relative;
  float: left;
  width: 2%;
  height: 60px;
}



.td-download-code {
  color: #000000;
}





/*RESPONSIVE*/


/***LOGIN: START***/

@media (min-width: 1250px) {

  .login-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-left: 20px;
    margin-top: 20px;
    width: 350px;
    /*height: 350px;*/
    z-index: 50;
    /*background-color:#333333;*/
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
  }

}


@media (max-width: 1250px) {

  .login-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 350px;
    margin-top: 20px;
    /*height: 350px;*/
    z-index: 50;
    /*background-color:#333333;*/
    -moz-box-shadow-bottom: 4px 4px 4px #929292;
    -webkit-box-shadow-bottom: 4px 4px 4px #929292;
    box-shadow: 4px 4px 4px #929292;
  }


}


/***LOGIN: END***/


/***NAV MENU: START***/

.topbar-container {
  width: 100%;
  height: 140px;
  background-color: transparent;
}


.menu-mobile-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: none;
}

.menu-mobile-btn img {
  max-width: 40px;
}

.mobile-menu-container {
  display: none;
}

@media (max-width: 1250px) {

  .topbar-container {
    height: 70px;
  }

  .menu-items-desktop {
    display: none;
  }

  .menu-mobile-btn {
    display: block;
  }

  .mobile-menu-container {
    display: block;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    width: 100%;
    height: calc(100% - 70px);
    top: 70px;
    z-index: 5000;
  }


  .mobile-menu-entry-wrapper {
    width: 70vw;
    margin: auto;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "MyriadPro";
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 3px solid transparent;
  }

  .mobile-menu-entry-wrapper.active {
    border-bottom: 3px solid #ffffff;
  }


  .mobile-menu-entry-wrapper a {
    color: #26a9b3;
    text-decoration: none;
    font-weight: bold;
  }

  .mobile-menu-entry-wrapper.active a {
    color: #ffffff !important;
  }


}

/***NAV MENU: END***/


/***PROFILE MENU: START***/

.profile-container {
  width: 400px;
  height: 140px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.profile-menu-container {
  position: absolute;
  left: 0;
  top: 140px;
  width: 120px;
  z-index: 910;
  /*height:500px;*/
  /*background-color:#ffd800;*/
}


@media (max-width: 1250px) {

  .profile-container {
    height: 70px;
    width: 120px;
  }

  .profile-menu-container {
    top: 70px;
  }

  .name-profile {
    display: none;
  }

  .div-img-avatar-wrapper {
    margin-top: 10px;
  }

  .img-avatar {
    width: 45px;
    height: 45px;
  }

  .avatar-profile {
    height: 70px;
  }

}

/***PROFILE MENU: END***/


/***LOBBY VIDEO AND ADDS: START***/

.main-screen-bottom.urovi2021 {
  width: 438px;
  /*height: 50px;*/
  background-image: none;
  background-color: transparent;
  padding: 10px;
}

.main-screen-bottom.sea2021 {
  width: 438px;
  /*height: 50px;*/
  background-image: none;
  background-color: transparent;
  padding: 10px;
}


.main-screen-bottom.urovi2021.tromboencuentros2021 {
  margin: 0 auto;
}

.main-screen-bottom.vitdah {
  width: 438px;
  /*height: 50px;*/
  background-image: none;
  background-color: transparent;
  padding: 10px;
}

.main-screen-bottom.vpracticvm {
  width: 438px;
  /*height: 50px;*/
  background-image: none;
  background-color: transparent;
  padding: 10px;
}


.banner-central-btns {
  display: block;
}

.banner-central-btns-mobile {
  display: none;
}

.side-screen img {
  max-width: 300px;
  max-height: 150px;
}


.side-screen img:hover {
  /* -ms-transform: scale(2); 
  -webkit-transform: scale(2); 
  transform: scale(2); 
  transition: transform .2s; */
}

.virtual-assistant-avatar {
  text-align: center;
  height: 100px;
}

.virtual-assistant-avatar.UROVI2021,
virtual-assistant-avatar.urovi2021 {
  display: none;
}

.virtual-assistant-avatar.ENCUENTROSEXPERTO2021,
virtual-assistant-avatar.encuentrosexperto2021,
.virtual-assistant-avatar.TROMBOENCUENTROS2021,
virtual-assistant-avatar.tromboencuentros2021 {
  display: none;
}

.virtual-assistant-avatar.VI-AmpliandoHorizontes,
.virtual-assistant-avatar.VI-AMPLIANDOHORIZONTES,
.virtual-assistant-avatar.vi-ampliandohorizontes {
  display: none;
}


.virtual-assistant-avatar.tromboencuentros2021,
.virtual-assistant-avatar.TROMBOENCUENTROS2021 {
  display: none;
}


.virtual-assistant-avatar.\31 REUNIONVIRTUALAREAS2021,
.virtual-assistant-avatar.\31 reunionvirtualareas2021 {
  display: none;
}

.virtual-assistant-avatar.\32 REUNIONVIRTUALAREAS2021,
.virtual-assistant-avatar.\32 reunionvirtualareas2021 {
  display: none;
}

.virtual-assistant-avatar.\33 REUNIONVIRTUALAREAS2022,
.virtual-assistant-avatar.\33 reunionvirtualareas2022 {
  display: none;
}

.virtual-assistant-avatar.\34 REUNIONVIRTUALAREAS2022,
.virtual-assistant-avatar.\34 reunionvirtualareas2022 {
  display: none;
}

.virtual-assistant-avatar img {
  max-height: 100px;
}


.side-screen {
  width: 300px;
  height: 150px;
  background-color: #000000;

}

.main-screen {
  background-color: #ffffff;
  border: 4px solid #4d4d4d;
  width: 450px;
  height: 200px;
}

.preview-dark {
  width: 450px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  text-align: center;
}

.top-mainscreen-wrapper {
  height: 30px;
  width: 100%;
}

.top-mainscreen-wrapper.ASEPB21,
.top-mainscreen-wrapper.asepb21 {
  display: none;
}

.main-screen.ASEPB21,
.main-screen.asepb21 {
  display: none;
}



.main-screen-wrapper {
  width: 458px;
  position: absolute;
  left: 380px;
  right: 380px;

}

.screens-container {
  height: 400px;
}

.screens-wrapper {
  position: relative;
  width: 1218px;
  height: 400px;
  margin: auto;
}

.side-screens-mobile {
  display: none;
}


.main-screen-bottom.vitdah span {
  background-color: #03753c;
  color: #ffffff;
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 27px;
  text-align: center;
}


.main-screen-bottom.urovi2021 span {
  background-color: #03753c;
  color: #ffffff;
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 27px;
  text-align: center;
}


.main-screen-bottom.sea2021 span {
  background-color: #662d8c;
  color: #ffffff;
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 27px;
  text-align: center;
}




.main-screen-bottom.vpracticvm span {
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 23px;
  text-align: center;
  background-color: #9d302f;
  color: #f3cda2;
}

.main-screen-bottom.vpracticvm span:hover {
  background-color: #f3cda2;
  color: #9d302f;

}


.main-screen-bottom.ASEPB21 span {
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 37px;
  text-align: center;
  background-color: #0699d7;
  color: #ffffff;
}

.main-screen-bottom.ASEPB21 span:hover {
  background-color: #29abe1;
  color: #ffffff;

}

.main-screen-bottom.ASEPB21 a {
  text-decoration: none;
}


.main-screen-bottom.ASEPB21 {
  background-image: none;
}





.virtual-assistant-bar {
  height: 30px;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 5px;
}


.virtual-assistent-container {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  width: 155px;
  z-index: 200;
}

.virtual-assistent-container.viicursotdah2022 {
  left: auto;
  right: 50px;
}


.legal-links-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #3f3c39;
  z-index: 199;
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #ffffff;
}


.lobby-content-container {
  position: absolute;
  top: 140px;
  width: auto;
  left: 0px;
  right: 0px;
  z-index: 250;
  margin: auto;
  height: calc(100% - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.lobby-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.lobby-content-container::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.lobby-content-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}



@media (max-width: 1250px) {

  .viicursotdah2022 .virtual-assistant-avatar {
    display: none;
  }

  .lobby-content-container {
    top: 80px;
  }



  .legal-links-container {
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }


  .virtual-assistent-container {
    bottom: 30px;
  }


  .virtual-assistant-bar {
    height: 20px;
  }


  .main-screen-bottom.vitdah span {
    font-size: 20px;
  }

  .main-screen-bottom.vitdah {
    margin: auto;
    width: 100%;
    padding: 0px;
    max-width: 400px;
  }


  .banner-central-btns {
    display: none;
  }

  .banner-central-btns-mobile {
    display: block;
  }

  .side-screen img {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 300px;
    margin: auto;
  }

  .side-screen img:hover {
    -ms-transform: none;
    /* IE 9 */
    -webkit-transform: none;
    /* Safari 3-8 */
    transform: none;
    transition: none;
  }

  .virtual-assistant-avatar {
    height: 50px;
  }

  .virtual-assistant-avatar img {
    max-height: 50px;
  }



  .side-screen {
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: center;
  }

  .top-sidescreen-wrapper,
  .top-screen-bar {
    display: none;
  }

  .side-screens-mobile {
    display: block;
    /*background-color: #cd0a17;*/
  }

  .screens-container {
    margin-top: 20px;
    height: auto;
  }

  .main-screen {
    width: auto;
  }

  .preview-dark {
    width: auto;
  }

  .preview-dark iframe {
    width: 340px;
  }

  .top-mainscreen-wrapper {
    display: none;
  }

  .screens-wrapper {
    width: 100%;
    height: 230px;
  }

  .screens-wrapper.ASEPB21 {
    display: none;
  }

  .main-screen-wrapper {
    left: 0px;
    right: 0px;
    margin: auto;
    width: 348px;
  }

  .left-screen-wrapper,
  .right-screen-wrapper {
    display: none;
  }

}


/***LOBBY VIDEO AND ADDS: END***/




/***ROOM: START***/

.right-column {
  float: right;
  width: 380px;
  /* The size of the fixed width column */
  height: 100%;
  margin-right: 40px;
}


.left-column-cont {
  margin-right: 500px;
  /* The size of the fixed width column + space between them 
      + sum of padding used in both columns. */
}

.left-column {
  width: 100%;
  float: left;
  margin-right: -500px;
  /* The size of the fixed width column + space between them.
      + sum of padding used in both columns. 
      IMPORTANT! use negative margin here. */
  margin-left: 40px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  /*height:700px;*/
  height: 100%;
}

.container-chat-mobile {
  display: none;
}

.chat-mobile {
  display: none;
}

.voting-chat-survey-mobile-container {
  display: none;
}


.econgress-room-chat-input-container {
  position: relative;
  float: left;
}

.econgress-room-chat-input-container input {
  background-color: #f2f2f2;
  border: 0px;
  /* margin-left: 20px; */
  padding: 8px;
  width: 320px;
}

@media (max-width: 1250px) {

  .econgress-room-chat-input-container {
    position: relative;
    float: none;
  }

  .econgress-room-chat-input-container input {
    background-color: #f2f2f2;
    border: 0px;
    /* margin-left: 20px; */
    padding: 8px;
    width: calc(100% - 16px);
  }

  .chat-mobile {
    display: block;
  }

  .container-chat-mobile {
    display: block;
    /*background-color: #cd0a17;*/
    /*height:50px;*/
    text-align: center;

    position: absolute;
    z-index: 299;
    bottom: 8%;
    width: 100%;

  }

  .chat-mobile-btn {
    background-color: #f6dd00;
    color: #000000;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-family: "MyriadPro";
    font-size: 18px;
    cursor: pointer;
  }

  .econgress-room-btn-side-panel-container {
    display: none;
  }

  .video-container {
    /*height: auto;*/
  }

  .right-column {
    display: none;
  }

  .left-column-cont {
    margin-right: 0px;
  }

  .left-column {
    margin-left: 0px;
  }


  .voting-chat-survey-mobile-container {
    display: block;
    position: absolute;
    width: 100vw;
    height: calc(100% - 90px);
    top: 90px;
    background-color: #ffffff;
    z-index: 300;
  }

  .close-voting-chat-survey-mobile-container {
    position: absolute;
    padding: 10px;
    width: 100vw;
    background-color: #ffffff;
    top: -50px;
    text-align: center;
  }

}

/***ROOM: END***/


/***SPEAKERS: START***/

.speaker-entry {
  position: relative;
  float: left;
  width: calc(50% - 20px);
  margin-left: 20px;
}

.previous-editions-entry {
  position: relative;
  float: left;
  width: calc(100% - 20px);
  margin-left: 20px;
}


.speakers-row {
  margin-top: 20px;
  position: relative;
  float: left;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}


.previous-editions-row {
  margin-top: 20px;
  position: relative;
  float: left;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 1250px) {

  .speakers-row {
    margin-top: 0px;
    width: 100%;
  }


  .previous-editions-row {
    margin-top: 0px;
    width: 100%;
  }


  .speaker-entry {
    width: calc(100%);
    margin-left: 0px;

    margin-bottom: 20px;
  }

  .previous-editions-entry {
    width: calc(100%);
    margin-left: 0px;

    margin-bottom: 20px;
  }

}

/***SPEAKERS: END***/


/*testing start*/
@media (max-width: 1250px) {

  .live-sessions-container {
    display: none;
  }

  .search-logout-wrapper {
    display: none;
  }




}

/*testing end*/


@media (max-width: 1250px) {

  .programme-speakers-content-container {
    top: 100px !important;
    height: calc(100% - 240px) !important;

  }

}

.programme-speakers-content-container {
  position: absolute;
  top: 160px;
  width: auto;
  left: 5vw;
  right: 5vw;
  z-index: 250;
  margin: auto;
  height: calc(100% - 330px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.programme-speakers-content-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.programme-speakers-content-container::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.programme-speakers-content-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.programme-speakers-content-container.UROVI2021 {
  height: calc(100% - 230px);
}






.networking-cuenca-container {
  position: absolute;
  top: 160px;
  width: auto;
  left: 10vw;
  right: 10vw;
  z-index: 250;
  margin: auto;
  height: calc(100% - 330px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.networking-cuenca-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.networking-cuenca-container::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.networking-cuenca-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}


.networking-btn-container {
  position: relative;
  float: left;
  /* width: 485px;
  height: 80px; */
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #555658;
  border: 2px solid #bbbdbf;
  cursor: pointer;
}

.networking-btn-text {
  font-family: "MyriadPro";
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
}

.arrow-cuenca-btn {
  position: absolute;
  right: 20px;
  top: 30px;
}


.tbl-programme {
  width: 100%;
  max-width: 1024px;
  background-color: white;
  margin: auto;
  font-family: "MyriadPro";
}


td.encuentrosexperto2021,
td.tromboencuentros2021 {
  color: #009b67;
}

.tbl-programme td {
  padding: 10px;
  font-size: 24px;
  vertical-align: top;
  text-align: left;
}

@media (max-width: 1250px) {
  .tbl-programme td {
    font-size: 12px;
    padding: 5px;
  }
}

.title-tr-tbl-programme {
  background-color: #1c537d;
  color: #ffffff;
}

.title-tr-tbl-programme-urovi2021 {
  background-color: #428f59;
  color: #ffffff;
}

.title-tr-tbl-programme-urovi2021.taller {
  background-color: #7e3f98;
  color: #ffffff;
}

.title-tr-tbl-programme-encuentrosexperto2021,
.title-tr-tbl-programme-tromboencuentros2021 {
  background-color: #94c900;
  color: #ffffff;
}

.tbl-programme td .subtitle {
  color: #99c65a;
}


.tbl-programme td .subtitle-coloquio {
  color: #1c537d;
  font-weight: bold;
}

.tbl-programme td .subtitle-urovi2021 {
  color: #03753c;
  font-weight: bold;
}

.tbl-programme td.day {
  color: #ffffff;
  text-align: left;
  background-color: #03753c;
  font-weight: bold;
}

.tbl-programme td.day.etev2021 {
  background-color: #662282;
}

.tbl-programme td.time {
  min-width: 170px;
}


.speaker-container {
  width: calc(100% - 20px);
  height: 200px;
  background-color: #ffffff;
  padding: 10px;
  position: relative;
}


.previous-editions-container {
  width: calc(100% - 20px);
  height: 350px;
  background-color: #ffffff;
  padding: 10px;
  position: relative;
}

.speaker-photo-name {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  height: 200px;
  width: 30%;
  background-color: #e9efda;

}


.speaker-photo-name img {
  max-width: 100%;
  max-height: 200px;
}



.previous-editions-img {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  height: 350px;
  width: 50%;
  background-color: #e9efda;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.previous-editions-img img {
  max-width: 100%;
  max-height: 350px;
}

.speaker-name {
  color: #226e15;
  font-weight: bold;
}

.speaker-cv {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  text-align: left;
  overflow-y: auto;
  height: 200px;
  /*width: 70%;*/
  margin-left: 10px;
  width: calc(70% - 10px);
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.speaker-cv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}


.speaker-cv::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.speaker-cv::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}





.previous-editions-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 20px;
  color: #000000;
  text-align: left;
  overflow-y: auto;
  height: 350px;
  /*width: 70%;*/
  margin-left: 10px;
  width: calc(50% - 10px);
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}

.previous-editions-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}


.previous-editions-text::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.previous-editions-text::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.previous-editions-text .year {
  background-color: #03753C;
  font-size: 25px;
  padding: 5px;
  color: #ffffff;
}


.cookies-info-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5000;
  text-align: center;
}


.cookies-accept-text-container {
  width: 100%;
  max-width: 1080px;
  max-height: 500px;
  font-family: "MyriadPro";
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.cookies-accept-text-container a {
  color: #ffffff;
}


.btn-accept-cookies {
  padding: 10px;
  font-family: "MyriadPro";
  color: #ffffff;
  background-color: #03753a;
  text-align: center;
  cursor: pointer;
  font-size: 25px;
}

.btn-accept-cookies:hover {
  background-color: #9bc65a;
}






.cookies-policy-container {
  text-align: left;
  height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
  background-color: #f2f2f2;
  color: #000000;
  font-size: 15px;
  padding: 10px;

}

.cookies-policy-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.cookies-policy-container::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.cookies-policy-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}



.legal-text-container {
  position: absolute;
  z-index: 300;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  max-width: 900px;
  text-align: left;
  height: calc(100% - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}


.legal-text-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.legal-text-container::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.legal-text-container::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}


.legal-text-btn-container {
  position: absolute;
  z-index: 300;
  max-width: 900px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #ffffff;
  bottom: 70px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}




.legal-links-container a {
  color: #ffffff;
}


.login-my-profile-container.privacy-popup {
  height: auto !important;
}


.tbl-privacy-policy-vitdah {
  width: 100%;
}

.tbl-privacy-policy-vitdah td {
  border: 1px solid #000000;
}

.tbl-privacy-policy-vitdah td.title {
  background-color: #f2eff5;
}


.sponsors-logos-container {
  text-align: center;
}


.sponsors-logos-container img {
  max-width: 100%;
  max-height: 100%;
}





.person-badge-spacer {
  display: none;
}


.person-badge-spacer.\32 congresocovid,
.person-badge-spacer.\32 CONGRESOCOVID {
  display: block;
  height: 90px;
}

.person-badge-spacer.sefc2022,
.person-badge-spacer.SEFC2022 {
  display: block;
  height: 170px;
}



.sponsors-logos-container.\32 congresocovid img {
  margin: 10px;
  border-radius: 5px;
  border: 10px solid #ffffff;
}


.sponsors-logos-container.\32 congresocovid .sponsor {
  max-width: 170px;
}

.sponsors-logos-container.\32 congresocovid .company {
  max-width: 130px;
}

.select-poster-cat-wrapper {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 80px;
  margin: auto;
  text-align: center;
  width: 600px;
  height: 50px;
  background-color: #03753a;
}

.select-poster-cat-wrapper select {
  width: 600px;
  height: 50px;
  font-size: 16px;
  font-family: "MyriadPro";
  padding: 10px;
}

.select-poster-cat-wrapper select:focus {
  outline: none;
}






.main-screen-bottom.CONGRESOCOVID span {
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 20px;
  text-align: center;
  background-color: #4069ff;
  color: #ffffff;
}

.main-screen-bottom.CONGRESOCOVID span:hover {
  background-color: #5578f9;
  color: #ffffff;

}

.main-screen-bottom.CONGRESOCOVID a {
  text-decoration: none;

}


.main-screen-bottom.CONGRESOCOVID {
  background-image: none;
  height: auto !important;
}





.form-registration {
  background-color: #ffffff;
  /*margin-left: 30px;
  margin-right: 30px;*/
  /*padding: 20px;*/
  font-size: 16px;

}




.form-registration label,
.form-registration select,
.form-registration textarea,
.form-registration input {
  width: 99.5%;
}

.form-registration h3 {
  background-color: #333333;
  color: #ffffff;
  padding: 10px;
  font-weight: normal;
}

.form-registration label {
  font-weight: bold;
  font-size: 14px;

}

.form-registration input {
  height: 25px;
  border: 1px solid #333333;
  margin-top: 5px;
}

.form-registration select {
  height: 25px;
  border: 1px solid #333333;
  margin-top: 5px;
}

.form-registration textarea {
  border: 0px;
  margin-top: 5px;
  resize: none;
}

.form-registration select:focus,
.form-registration textarea:focus {
  outline: none;
}

.form-registration .input-wrapper {
  margin-top: 15px;
}

.form-registration .btn-submit {
  padding: 5px;
  background-color: #00a99d;
  color: #ffffff;
  font-family: "MyriadPro";
  font-size: 20px;
  text-align: center;
  height: 35px;
  border: 0px;
  cursor: pointer;
  max-width: 300px;
  margin: 0 auto;
}

.form-registration .btn-submit:focus {
  outline: none;
}



.registration-form-privacy-policy {
  height: 300px;
  background-color: #f4f4f2;
  padding: 10px;
  overflow: auto;
  font-size: 14px;
  font-family: "MyriadPro";
}



.registration-form-privacy-policy::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d4d4d;
}

.registration-form-privacy-policy::-webkit-scrollbar {
  width: 6px;
  background-color: #4d4d4d;
}

.registration-form-privacy-policy::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

#registration-privacy-policy {
  width: auto !important;
}






.recover-password-container {
  position: absolute;
  z-index: 300;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 30px;
  font-family: "MyriadPro";
  font-size: 14px;
  color: #000000;
  max-width: 900px;
  text-align: left;
  height: 350px;
  max-width: 500px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}



.recover-password-btn-container {
  position: absolute;
  z-index: 300;
  width: 500px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: #ffffff;
  top: 450px;
  bottom: auto;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.bar-percentage {
  padding: 4px;
  background-color: #cccccc;
  width: 0%;
  text-align: right;
  white-space: nowrap;
}


.p-login-support-contacts {
  font-family: "MyriadPro";
  font-size: 16px;
  color: #ffffff;
}

.p-login-support-contacts.semnim2021 {
  font-family: "MyriadPro";
  font-size: 13px;
}

.p-login-support-contacts a {
  color: #ffffff;
}

.p-tromboencuentros2021-soon {
  text-align: center;
}

.p-tromboencuentros2021-soon span {
  background-color: #03753c;
  padding: 15px;
  font-family: "MyriadPro";
  font-size: 35px;
  color: #ffffff;
}

.container-pre-stream-tromboencuentros2021 {
  text-align: center;
  height: calc(100vh - 240px);
}


.container-pre-stream-tromboencuentros2021 img {
  max-width: 100%;
  max-height: 100%;
}

.container-pre-stream-tromboencuentros2021 p {
  background-color: #03753c;
  padding: 10px;
  font-family: "MyriadPro";
  font-size: 20px;
  color: #ffffff;
  max-width: 880px;
  margin: 0 auto;
  margin-top: 10px;
}


.tromboencuentros2021-pic-ponente {
  max-width: 100px;
}

.tromboencuentros2021-cv-ponente {
  background-color: #a1a1a1;
  text-decoration: none;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.speakers-cv-tromboencuentros2021 {
  /*line-height: 1.5;*/
}

.secretariat-phone {
  font-family: "MyriadPro";
  font-size: 12px;
}

.econgress-admin-question-container {
  font-family: "MyriadPro";
  padding: 10px;
  margin: 10px;
  max-width: 500px;
}

.econgress-admin-question-title {
  font-size: 20px;
  font-weight: bold;
}


.eposter-logos-top {
  max-height: 35px !important;
  margin-left: 10px;
  margin-top: 5px;
}


.td-title.isin2021-separator {
  text-align: center;
  background-color: #1b3a74;
  color: #ffffff;
  cursor: default;
}

.voting-assembly-text {
  position: relative;
  float: left;
  font-family: "MyriadPro";
  font-size: 15px;
  color: #333333;
  width: 450px;
  margin-left: 20px;
}

.voting-assembly--text a {
  color: #26a9b3;
  text-decoration: none;
}

.programme-entry-viencuentroetev {
  position: relative;
  float: left;
  width: 700px;
  margin-right: 35px;
}

.programme-entry-viencuentroetev .tbl-programme .speaker {
  font-size: 20px;
}

.etev2021-lobby-container {

  /*height: 600px;*/
  max-width: 900px;
  margin: auto;
  margin-top: 50px;
}

.access-etev2021-lobby {
  width: 100%;
  height: 300px;
  margin: auto;
  text-align: center;
}

@media (max-width: 520px) {

  .access-etev2021-lobby img {
    max-width: 400px;
  }

}

.btns-etev2021-lobby {
  width: 33.3%;
  height: 50px;
  text-align: center;
  position: relative;
  float: left;
}


.btns-etev2021-lobby-container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  height: 150px;
  display: block;
}

.btns-etev2021-lobby-container-mobile {
  display: none;
  text-align: center;
}


@media (max-width: 800px) {

  .btns-etev2021-lobby-container {
    display: none;
  }

  .btns-etev2021-lobby-container-mobile {
    display: block;
  }

}



.rovi-etev2021-lobby-container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  height: 30px;
  text-align: center;
}

.rovi-etev2021-lobby-container img {
  max-height: 100px;
}


.poster-prize-tag {
  color: #ffffff;
  background-color: #662282;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: normal;
}

.text-my-bag {
  font-family: "MyriadPro";
  font-size: 15px;
  color: #333333;
  position: relative;
  float: left;
}







.room-sessions-wrapper {
  position: relative;
  float: left;
  width: 301px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}


.room-sessions-area {
  /*height:400px;*/
  background-color: #ffffff;
  border: 8px solid #333333;
  padding: 5px;
  height: 285px;
  max-height: 285px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #f6dd00 #F5F5F5;
}

.days-sessions-container.rooms3 {
  position: absolute;
  top: 160px;
  width: 980px;
  z-index: 250;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 220px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4d4d4d #F5F5F5;
}



@media (max-width: 970px) {

  .room-sessions-wrapper {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }


  .room-sessions-area {
    height: auto;
  }


  .days-sessions-container.rooms3 {
    width: 100%;
    top: 80px;
  }

  .slide-bar.rooms3,
  .slide-bar-btn.rooms3 {
    display: none;
  }

  .days-sessions-container.rooms3,
  .room-sessions-container {
    /*overflow-y: hidden;*/
  }

}



.days-sessions-container-info {
  text-align: right;
  font-family: "Dotmatrx";
  font-size: 12px;
  color: #f6dd00;
  position: relative;
  float: right;
  margin-right: 25px;
  background-color: #000000;
  padding: 10px;
  text-transform: uppercase;
}


.badge-person-name.seaic2021,
.badge-person-name.SEAIC2021,
.badge-congress-title.seaic2021,
.badge-congress-title.SEAIC2021 {
  display: none !important;
}

.badge-congress-title.sefc2022,
.badge-congress-title.SEFC2022 {
  display: none !important;
}



.cod-evento-rovi {
  font-size: 18px;
  margin-left: 50px;
}


.div-rovi-sponsor-track{
  position: absolute;
  left: 10px;
  bottom: 10px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 5px;
  font-family: "MyriadPro";
  font-size: 13px;
  color: #000000;
}

.div-rovi-sponsor-track img{
  max-width: 80px;
}


.econgress-room-btn-side-panel-container.cnp2023{
  display: none;
}

.econgress-room-btn-side-panel-container.cnp2024{
  display: none;
}